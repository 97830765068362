import React, {FC, useEffect, useRef, useState} from 'react';
import {styles} from "../ChatMessages/styles";
import {Image, ScrollView, Text, TouchableOpacity, View} from "react-native";
import {getFullDateTime} from "../../../utils/datetime";
import {getEventComments} from "../../../api/events/getEventComments/getEventComments";
import {getAvatar, getMediaPreviewSrc} from "../../../utils/common";
import {colors} from "../../../constants/Colors";
import {capitalizeFirstLetter} from "../../../utils/text";
import {translate} from "../../../utils/translate";
import {useNavigation} from "@react-navigation/native";
import {SCREENS} from "../../../navigation/screenName";

interface ICommentsList {
    eventId: number;
    eventDetail: any;
    needCommentsRefresh:boolean;
    setNeedCommentsRefresh: any;
    navigateToUser: any
}

interface IComment {
    comment: string;
    created_at: string;
    dt_added: number;
    event_id: number;
    id: number;
    is_shown: number;
    updated_at: string | null;
    user_id: number;
    user:any;
}

const CommentsList:FC<ICommentsList> = ({
                                            eventId,
                                            eventDetail,
                                            needCommentsRefresh,
                                            setNeedCommentsRefresh,
                                            navigateToUser
}) => {

    const [comments, setComments] = useState<IComment[]>([])
    const scrollViewRef = useRef();

    useEffect(() =>{
        getEventComments({evt_id:eventId}).then( (data:IComment[]) => {
            setComments(data);
            setNeedCommentsRefresh(false);
        });
    }, [needCommentsRefresh])

    /*
    const navigateToUser = (id:any) => {
        if(id) {
            // @ts-ignore
            navigation.navigate('CommonNavigator', {screen: SCREENS.ProfileUserScreen, params: {userId: id}});
        }

    }

     */

    return (
        <>
            {comments && comments.length > 0 &&
            <View style={{marginTop: 10}}>
                <Text style={{
                    color: colors.greenPH,
                    fontSize: 18,
                    fontWeight: "500"
                }}>{capitalizeFirstLetter(translate('comment.comments'))}</Text>
            </View>
            }
            <View
                style={[styles.messageViewer,{width:"100%"}]}
            >
                {comments &&
                <View style={[styles.messageBody, {marginTop:0}]}>
                    {comments?.map((comment: IComment) =>
                        <View key={comment.id}
                              style={[
                                  styles.messageSender,
                                  comment.user_id == eventDetail.user_id && {backgroundColor:"#ffffff"},
                                  {flexDirection: "row",justifyContent: "space-between"},

                              ]}
                        >
                            <View style={{}}>
                                <Text style={[styles.messageText, {fontSize:15, lineHeight: 19, fontWeight: "500", color: colors.black }]}>{comment.comment}</Text>
                                <View style={{flexDirection:"row"}}>
                                    <Text style={[styles.messageText, {color: colors.greenLight} ]}>{comment?.user?.full_name}</Text>
                                    <Text style={[styles.messageTime, {color: colors.greenLight} ]}>{getFullDateTime(comment.created_at)}</Text>
                                </View>
                            </View>
                            <View style={{justifyContent: "flex-end"}}>
                                <TouchableOpacity onPress={ () => navigateToUser(comment?.user?.id ?? false) }>
                                    <Image style={styles.messageImageUser} resizeMode="contain" source={getMediaPreviewSrc(comment?.user?.avatar_media)}/>
                                </TouchableOpacity>
                            </View>
                        </View>
                    )}
                </View>
                }
            </View>
        </>
    );
};

export default CommentsList;
