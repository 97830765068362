import React, {FC} from 'react';
import {FlatList, Image, ListRenderItemInfo, TouchableOpacity, View} from "react-native";
import {IEvent, IPet} from "../../../types";

import {getMediaFirst, getMediaFull, getMediaPreview, getMediaPreviewSrc} from "../../../utils/common";
import styles from "./styles";

interface IFeedListProps {
    data: IEvent[],
    onItemPress: (id:number) => void,
    nestedScrollEnabled: boolean
}

const FeedList:FC<IFeedListProps> = ({data, onItemPress, nestedScrollEnabled = false, ... props}) => {
    const renderItem = ({item}:ListRenderItemInfo<IEvent>) => (
        <TouchableOpacity style={styles.itemContainer} key={`event_${item.id}`} onPress={ () => { onItemPress(item.id)} }>
            <Image style={styles.itemImg}
                   source={getMediaPreviewSrc(getMediaFirst(item.media))}/>
        </TouchableOpacity>
    );
    return (
        <FlatList
            scrollEnabled={false}
            data={data}
            renderItem={renderItem}
            numColumns={3}
            keyExtractor={(item: IEvent) => item.id.toString()}
            columnWrapperStyle={styles.flatlistColumn}
            contentContainerStyle={styles.listEvent}
            nestedScrollEnabled
        />
    );
};

export default FeedList;
