import React, {FC, useEffect, useState} from 'react';
import {Text, TextInput, View} from "react-native";
import BackButton from "../../../../components/UI/BackButton/BackButton";
import {translate} from "../../../../utils/translate";
import ButtonSaveEvent from "../../../../components/Project/ButtonSaveEvent/ButtonSaveEvent";
import {SCREEN_WIDTH} from "../../../../constants/globalStyles";
import SubButton from "../../../../components/UI/SubButton/SubButton";
import Input from "../../../../components/UI/Input/Input";
import {accountIcon} from "../../../../constants/images";
import {IRegistrationFormData} from "../types";
import {colors} from "../../../../constants/Colors";
import styles from "../../../Event/EventFormScreen/styles";
import DropDownPicker from "react-native-dropdown-picker";
import {getRoleTypes} from "../../../../api/role/getRoleTypes/getRoleTypes";
import ToggleButton from "../../../../components/UI/ToggleButton/ToggleButton";

interface IRegistrationUR {
    formData:IRegistrationFormData,
    onFieldChange: ({form, field, data}:{form?:string | undefined, field:string, data: any}) => void
}

const RegistrationUR:FC<IRegistrationUR> = ({formData,onFieldChange}) => {
    const [roleTypeValue, setRoleTypeValue] = useState<number>(0);
    const [openRoleTypeList, setOpenRoleTypeList] =  useState<boolean>(false);
    const [roleTypeList, setRoleTypeList] =  useState<any[]>([]);
    const [addressSync, setAddressSync] = useState(false);

    useEffect(() => {
        onFieldChange({field: "ur_type", data: roleTypeValue})
    },[roleTypeValue]);

    useEffect(() => {
        getRoleTypes().then(resp => {
            let tmpList:any[] = [];
            resp.data.map( (item:any) => {
                tmpList.push({
                    label: item.name,
                    value: item.id
                });
            });
            setRoleTypeList(tmpList);
        })
    }, []);
    return (
        <View style={{width:SCREEN_WIDTH*0.9, marginTop:20}}>
            <DropDownPicker
                open={openRoleTypeList}
                setOpen={setOpenRoleTypeList}
                value={roleTypeValue}
                items={roleTypeList}

                //@ts-ignore: Unreachable code error
                setValue={setRoleTypeValue}
                listMode="SCROLLVIEW"
                placeholder={translate('registration.ur_type')}
                placeholderStyle={{
                    color: 'gray',
                    // fontWeight: "bold"
                }}
                dropDownDirection="AUTO"
                style={[styles.dropdown,{marginTop:10, backgroundColor: "transparent"}]}
                dropDownContainerStyle={styles.dropdownContainer}
            />

            <View>
                <Input
                    valueText={formData.ur_name}
                    onChange={(value: any) => onFieldChange({field: "ur_name", data: value})}
                    placeHolderText={translate("registration.ur_name")}
                />
            </View>
            <View>
                <Input
                    valueText={formData.website}
                    onChange={(value: any) => onFieldChange({field: "website", data: value})}
                    placeHolderText={translate("registration.website")}
                />
            </View>
            <View>
                <TextInput
                    value={formData.ur_description}
                    placeholder={translate('description')}
                    placeholderTextColor={colors.halfCedar}
                    style={styles.bigInput}
                    onChangeText={(data) => {
                        onFieldChange({field: 'ur_description', data: data})
                    }}
                />
            </View>
            {formData.role == 3 &&
            <View>
                <Input
                    valueText={formData.ur_fio}
                    onChange={(value: any) => onFieldChange({field: "ur_fio", data: value})}
                    placeHolderText={translate("registration.ur_fio")}
                />
            </View>
            }
            {formData.role == 3 &&
            <View>
                <Input
                    valueText={formData.ur_passport}
                    onChange={(value: any) => onFieldChange({field: "ur_passport", data: value})}
                    placeHolderText={translate("registration.ur_passport")}
                />
            </View>
            }
            {formData.role == 2 &&
            <View>
                <Input
                    valueText={formData.ur_legalname}
                    onChange={(value: any) => onFieldChange({field: "ur_legalname", data: value})}
                    placeHolderText={translate("registration.ur_legalname")}
                />
            </View>
            }
            {formData.role == 2 &&
            <View>
                <Input
                    valueText={formData.ur_kpp}
                    onChange={(value: any) => onFieldChange({field: "ur_kpp", data: value})}
                    placeHolderText={translate("registration.ur_kpp")}
                />
            </View>
            }

            <View>
                <Input
                    valueText={formData.ur_ogrn}
                    onChange={(value: any) => onFieldChange({field: "ur_ogrn", data: value})}
                    placeHolderText={translate("registration.ur_ogrn")}
                />
            </View>
            <View>
                <Input
                    valueText={formData.ur_inn}
                    onChange={(value: any) => onFieldChange({field: "ur_inn", data: value})}
                    placeHolderText={translate("registration.ur_inn")}
                />
            </View>
            <View>
                <Input
                    valueText={formData.ur_bik}
                    onChange={(value: any) => onFieldChange({field: "ur_bik", data: value})}
                    placeHolderText={translate("registration.ur_bik")}
                />
            </View>
            <View>
                <TextInput
                    value={formData.ur_rekv}
                    placeholder={translate('registration.ur_rekv')}
                    placeholderTextColor={colors.halfCedar}
                    style={styles.bigInput}
                    onChangeText={(data) => {
                        onFieldChange({field: 'ur_rekv', data: data})
                    }}
                />
            </View>
            <View>
                <Input
                    valueText={formData.ur_address}
                    onChange={(value: any) => onFieldChange({field: "ur_address", data: value})}
                    placeHolderText={translate("registration.ur_address")}
                />
            </View>
            <View>
                <Input
                    valueText={formData.ur_factaddress}
                    onChange={(value: any) => onFieldChange({field: "ur_factaddress", data: value})}
                    placeHolderText={translate("registration.ur_factaddress")}
                />
            </View>
            <View style={{width:"100%"}}>
            <ToggleButton onToggle={(value) => {
                    const newValue = !value;
                    if(newValue) {onFieldChange({field: "ur_factaddress", data: formData.ur_address}); setAddressSync(true);}
                    else {setAddressSync(false);}
                }} value={addressSync} text={translate("registration.ur_factaddress")} />
           </View>
        </View>
    );
};

export default RegistrationUR;
