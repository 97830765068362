import React, {createRef, FC, useEffect, useRef, useState} from 'react';
import {StyleSheet} from "react-native";
import EventCard from "../../../components/Project/EventCard/EventCard";
import BackButton from "../../../components/UI/BackButton/BackButton";
import {translate} from "../../../utils/translate";
import ViewScreen from "../../../components/Project/ViewScreen/ViewScreen";
import ViewBottom from "../../../components/UI/ViewBottom/ViewBottom";
import InputSend from "../../../components/UI/InputSend/InputSend";
import {Icons} from "../../../constants/images";
import {sendMessage} from "../../../api/chats/sendMessage/sendMessage";
import {addCommentToEvent} from "../../../api/events/addCommentToEvent/addCommentToEvent";
import {showMessage} from "react-native-flash-message";
import {Dictionary} from "../../../locales/dictionary";
import {responseWithBadWords} from "../../../utils/response";


interface IEventScreen {
    eventId?: number,
    route: any,
    navigation: any,
}

const EventScreen:FC<IEventScreen> = ({
                                          navigation,
                                          route,
                                          ...props
                                      }) => {

    const [comment, setComment] = useState<string>('');
    const [needCommentsRefresh, setNeedCommentsRefresh] = useState<boolean>(true);

    //TODO: EventScreen types - route and navigation
    const {eventId = 0 } = route.params;
    const inputRef = useRef();

    const onCommentChange = (data:any) => {
        setComment(data);
    }
    const onCommentAdd = ( ) => {
        if(eventId && comment != '') {
            const payload = {
                "evt_id": eventId,
                "comment": comment,
            }
            addCommentToEvent(payload).then( (resp) => {
                responseWithBadWords({
                    resp: resp,
                    messageSuccess: Dictionary.comment.addSuccess,
                    messageBadWords: Dictionary.errors.badWords,
                    messageError: "errors." + resp.message,
                    callBackSuccess: () => {
                        //@ts-ignore
                        inputRef?.current?.clear();
                        setComment('');
                        setNeedCommentsRefresh(true);
                    }
                })
                /*
                if(resp.success) {
                    showMessage({
                        message: translate(Dictionary.comment.addSuccess),
                        type: "success"
                    })
                    //@ts-ignore
                    inputRef?.current?.clear();
                    setComment('');
                    setNeedCommentsRefresh(true);
                    return;
                } else if (resp.originResp.data.bad_words) {
                    showMessage({
                        message: translate(Dictionary.errors.badWords) + ': ' + resp.originResp.data.bad_words.join(', '),
                        type: "danger"
                    })
                    return;
                } else {
                    showMessage({
                        message: translate(resp.message),
                        type: "danger"
                    })
                    return;
                }

                 */

            })
        }
    }

    return (
        <ViewScreen keyboardVerticalOffset={25}>
            <BackButton
                text={translate('back')}
                action={() => navigation.goBack()}
            />
            <EventCard
                eventId={eventId}
                needCommentsRefresh={needCommentsRefresh}
                setNeedCommentsRefresh={setNeedCommentsRefresh}
            />
            <ViewBottom marginBottom={0}>
                <InputSend
                    inputRef={inputRef}
                    onChange={ onCommentChange }
                    onPress = { onCommentAdd }
                    rightIcon={Icons.messageSend}
                    placeHolderText={translate('comment.add')} />
            </ViewBottom>
        </ViewScreen>
    );
};

export default EventScreen;
