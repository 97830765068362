import * as React from 'react';
import {BottomTabParamList, HomeParamList} from '../types';
import {BottomTabNavigationOptions, createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import Home from '../screens/Home/Home';
import Favorites from '../screens/Favorites/Favorites';
import Map from '../screens/Map/Map';
import Message from '../screens/Message/Message';
import CustomTabBar from '../components/UI/CustomTabBar/CustomTabBar';
import {SCREENS} from './screenName';
import {createStackNavigator, StackNavigationOptions} from '@react-navigation/stack';
import ProfileUserScreen from "../screens/Profile/ProfileUserScreen/ProfileUserScreen";
import ProfileSettings from "../screens/Profile/ProfileSettings/ProfileSettings";
import ProfileNotifications from "../screens/Profile/ProfileNotifications/ProfileNotifications";
import ProfileApp from "../screens/Profile/ProfileApp/ProfileApp";
import ProfilePassword from "../screens/Profile/ProfilePassword/ProfilePassword";

const Tab = createBottomTabNavigator<BottomTabParamList>();
const Stack = createStackNavigator<HomeParamList>();
const tabScreenOptions:BottomTabNavigationOptions = {
    headerShown: false,
    tabBarAllowFontScaling: true,
    tabBarHideOnKeyboard: true,
    tabBarStyle: { display: "none" }
}
const stackScreenOptions:StackNavigationOptions = {
    headerShown: false,
}

function HomeStack(props: any) {

    return(
        <Stack.Navigator initialRouteName={SCREENS.HomeScreen} >
            <Stack.Screen name={SCREENS.HomeScreen} component={Home} options={stackScreenOptions} />
            {/*
            <Stack.Screen name={SCREENS.AddEvent} component={EventForm} options={stackScreenOptions} />
            <Stack.Screen name={SCREENS.HomeScreenEvent} component={EventScreen} options={stackScreenOptions } />
            */}
        </Stack.Navigator>
    )
}

function FavoritesStack() {

    return(
        <Stack.Navigator initialRouteName={SCREENS.FavoritesScreen} >
            <Stack.Screen name={SCREENS.FavoritesScreen} component={Favorites} options={stackScreenOptions} />
            {/*
            <Stack.Screen name={SCREENS.FavoritesScreenEvent} component={EventScreen} options={stackScreenOptions} />
            */}
        </Stack.Navigator>
    )
}

function ProfileStack() {

    return(
        <Stack.Navigator initialRouteName={SCREENS.ProfileUserScreen} >
            <Stack.Screen name={SCREENS.ProfileUserScreen} component={ProfileUserScreen} options={stackScreenOptions} />
            <Stack.Screen name={SCREENS.ProfileSettings} component={ProfileSettings} options={stackScreenOptions} />
            <Stack.Screen name={SCREENS.ProfileNotifications} component={ProfileNotifications} options={stackScreenOptions} />
            <Stack.Screen name={SCREENS.ProfileApp} component={ProfileApp} options={stackScreenOptions} />
            <Stack.Screen name={SCREENS.ProfilePassword} component={ProfilePassword} options={stackScreenOptions} />
        </Stack.Navigator>
    )
}

function BottomTabNavigator(props:any) {
    return (
        <Tab.Navigator
            tabBar={(props: any) => <CustomTabBar {...props} />}
        >
            <Tab.Screen
                name={SCREENS.Home}
                component={HomeStack}
                options={tabScreenOptions}

            />
            <Tab.Screen
                name={SCREENS.Favorites}
                //component={Favorites}
                component={FavoritesStack}
                options={tabScreenOptions}


            />
            <Tab.Screen
                name={SCREENS.Map}
                component={Map}
                options={tabScreenOptions}
            />
            <Tab.Screen
                name={SCREENS.Messages}
                component={Message}
                options={tabScreenOptions}
            />
            <Tab.Screen
                name={SCREENS.Profile}
                //component={ ProfileUserScreen }
                component={ProfileStack}
                options={tabScreenOptions}
            />
        </Tab.Navigator>
    );
}

export default BottomTabNavigator;
