import Geocoder from "react-native-geocoding";
import GetLocation from "react-native-get-location";

export const  getLocation = async () => {
    const location = await GetLocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 15000,
    })
    return location;
};

export const geocodeReverse = async (address: string) => {
    console.log('geocode Reverse');
    const resp = await Geocoder.from(address);
    return resp;
}

export const geocodeLocation = async (location: any) => {
    console.log('geocode TO');
    let addr: string[] = [
        '',
        '',
        ''
    ];

    let searches = [
        {name:'city',type:'administrative_area_level_2',indx: 0,field: 'long_name', isFound: false},
        {name:'street',type:'route',indx: 1,field: 'long_name', isFound: false},
        {name:'home',type:'street_number',indx: 2, field: 'long_name', isFound: false}
    ];

    try {
        const resp = await Geocoder.from({lat: location.latitude, lng: location.longitude});
        resp.results[0].address_components.map(addressComponent => {
            searches = searches.map( (searchItem, indx) => {
                if(!searchItem.isFound && addressComponent.types && addressComponent.types.includes(searchItem.type)) {
                    //@ts-ignore
                    addr[searchItem.indx] = addressComponent[searchItem.field];
                    searchItem.isFound = true;
                }
                return searchItem;
            });
        });
        return addr;
    }
    catch (error) {
        console.warn(error)
        return addr;
    }
    /*
    const output = Geocoder.from({lat: location.latitude, lng: location.longitude})
        .then( (json) => {
          console.log('Address2', json);
          let addr = [
            '',
            '',
            ''
          ];
          let searches = [
            {name:'city',type:'administrative_area_level_2',indx: 0,field: 'long_name', isFound: false},
            {name:'street',type:'route',indx: 1,field: 'long_name', isFound: false},
            {name:'home',type:'street_number',indx: 2, field: 'long_name', isFound: false}
          ];

          json.results[0].address_components.map(addressComponent => {
            searches = searches.map( (searchItem, indx) => {
              if(!searchItem.isFound && addressComponent.types && addressComponent.types.includes(searchItem.type)) {
                //@ts-ignore
                addr[searchItem.indx] = addressComponent[searchItem.field];
                searchItem.isFound = true;
              }
              return searchItem;
            });
          });
          return addr;

        })
        .catch( (error) => console.warn(error));
    return output;

     */
}
