import React, {FC} from 'react';
import {Text, TouchableOpacity, View} from "react-native";
import {colors} from "../../../constants/Colors";
import {translate} from "../../../utils/translate";
import {IMedia} from "../../../types";
// @ts-ignore
//import Video from "react-native-video-controls";
import {getMediaFullSrc} from "../../../utils/common";

interface IVideoPlayer {
    media:IMedia,
    onClose: () => any,
    isVideoPaused: boolean,
}

const VideoPlayer:FC<IVideoPlayer> = ({media, onClose, isVideoPaused, ...props}) => {

    return (
        <View style={{flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.black}}
        >
            <TouchableOpacity
                onPress={ onClose }
                style={{padding: 20}}
            >
                <Text style={{color: colors.white}}>{translate('common.close')}</Text>
            </TouchableOpacity>

            <Video
                source={getMediaFullSrc(media)}
                paused={isVideoPaused}
                controls
                showOnStart
                tapAnywhereToPause
                resizeMode={"contain"}
                style={{
                    aspectRatio: 1,
                    width: "100%"
                }}
            />
        </View>
    );
};

export default VideoPlayer;
