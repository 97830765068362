import {StyleSheet} from 'react-native';
import {SCREEN_WIDTH} from "../../../constants/globalStyles";
import {colors} from "../../../constants/Colors";

const ITEM_WIDTH = (SCREEN_WIDTH - 40) / 3;
//const ITEM_HEIGHT = ITEM_WIDTH * 1.55;
const ITEM_HEIGHT = ITEM_WIDTH;

const styles = StyleSheet.create({
    listEvent: {},
    flatlistColumn: {
        justifyContent: 'space-between',
    },
    separator:{
        width:10,
    },

    itemContainer: {
        width: ITEM_WIDTH,
        height: ITEM_HEIGHT,
        marginBottom: 1,
        borderRadius: 0,
        borderWidth: 2,
        borderColor: colors.white,
    },
    itemImg: {
        width: ITEM_WIDTH,
        height: ITEM_HEIGHT - 3,
        resizeMode: "cover"
    },
});

export default styles;
