import {API_EVENTS_MODEL} from "../const";
import {API_BASE_URL, apiCall} from "../../api";

export const addEventMediaMultiply = async (payload:any) => {
    const methodUrl = [API_EVENTS_MODEL.url,API_EVENTS_MODEL.methods.addEventMediaMultiply.url].join('/');
    const resp = await apiCall(
        'post',
        API_BASE_URL + methodUrl,
        payload
    );

    if(resp && resp.data.status && resp.data.message) {
        return resp.data.message;
    } else {
        return [];
    }
};
