import {StyleSheet} from "react-native";
import {colors} from "../../../constants/Colors";

const styles = StyleSheet.create({
    input: {
        height: 50,
        fontSize: 16,
        marginTop: 5,
    },
    bigInput: {
        height: 50,
        borderColor: colors.greenPH,
        borderWidth: 0.5,
        marginTop: 33,
        textAlign: 'center',
    },
    image: {
        height: 79,
        width: 102,
        alignSelf: 'center',

    },
    submitButton: {
        marginTop: 15,
    },
});

export default styles;
