import React, {FC, useCallback, useEffect, useState} from 'react';
import ViewScreen from "../../components/Project/ViewScreen/ViewScreen";
import ChatList from "../../components/Project/ChatList/ChatList";
import {IChatListItem} from "../../components/Project/ChatList/types";
import {useDispatch, useSelector} from "react-redux";
import {useIsFocused} from "@react-navigation/native";
import {getChats} from "../../api/chats/getChats/getChats";
import {IScreen} from "../../types";
import {ScrollView, View} from "react-native";
import styles from "../Home/styles";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import {translate} from "../../utils/translate";
import {setEvents} from "../../redux/GlobalRedux/actions/actionCreator";
import {useDebouncedCallback} from "use-debounce";

interface IMessageScreen {
    navigation: any;
    route:any;
}

const Message:FC<IMessageScreen> = ({navigation, route, ...props }) => {
    const dispatch = useDispatch();
    const isFocused = useIsFocused();
    const userInfo = useSelector((state: any) => state?.session?.userData);
    const [searchString, setSearchString] = useState<string>('');
    const [screenState, setScreenState] = useState({
        searchString: '',
        isChatsLoading: true,
    })

    const [chats, setChats] = useState<any[]>([]);

    const onChatPress = (item: IChatListItem) => {
        //console.warn('Chat Touched', item)
        navigation.navigate('CommonNavigator', {screen: 'ChatScreen', params: {chatId: item.id}});
    };
    const getChatsList = async (test?:any) => {
        let payload:{user_id?:any,keyword?:string} = { user_id: userInfo.user_id?.toString() };
        if(searchString && searchString != '') {
            payload.keyword = searchString;
        }
        let output:any[] = [];
        try {
            output = await getChats(payload)
            return output.reverse();
        } catch (error) {
            return output;
        }
    }

    useEffect(() => {
        console.log("CHATS", chats);
    }, [chats]);

    useEffect(() => {
        getChatsList().then( response => setChats(response) );
    }, [isFocused]);

    const onSearch = async (text:string) => {
        setSearchString(text);
        if(text.length >= 3 || text == '') {
            debouncedChats(text);
        }
    }
    const debouncedChats = useDebouncedCallback(
        (value) => {
            console.log('DEBOUNCED CHATS', value);
            //setSearchString(value);
            getChatsList().then( response => setChats(response) );
        },
        10,
        // The maximum time func is allowed to be delayed before it's invoked:
        { maxWait: 15 }
    );
    useEffect(
        () => () => {
            debouncedChats.flush();
        },
        [debouncedChats]
    );
/*
    useEffect(() => {
        console.log('SEARCH TO API', searchString);
        setScreenState({...screenState, isChatsLoading: true})
        getChatsList().then( resp => {
            setChats(resp);
            setScreenState({...screenState, isChatsLoading: false})
            //dispatch(setEvents(resp))
        } );
    }, [searchString]);
*/
    return (
        <ViewScreen keyboardVerticalOffset={-170}>
            <View style={styles.searchInput}>
                <SearchInput
                    placeholderText={translate('placeHolderSearchHome')}
                    onSearchPress={ onSearch }
                    //onClearPress={ onSearchClear }
                />
            </View>
            <ScrollView>
            {chats.length > 0 && (
                <ChatList chats={chats} onChatPress={onChatPress} />
            )}
            </ScrollView>
        </ViewScreen>

    );
};

export default Message;
