import {IAPI_USERS_MODEL} from "./types";

export const API_USERS_MODEL = {
    entity: 'user',
    url: 'user',
    methods: {
        getUserById: {
            url: 'get-user'
        },
        addFirebaseToken: {
            url: 'add-firebase-token'
        },
        addGeo: {
            url: 'add-geo'
        },
        updateNotifSettings: {
            url: 'update-notif-settings'
        },
        updatePassword: {
            url: 'update-password'
        },
        resetPasswordByEmail: {
            url: "reset-request-by-email"
        },
        updateNotifMsgs: {
            url: 'update-notif-msgs'
        },
        updateNotifComments: {
            url: 'update-notif-comments'
        },
        updateNotifFavevents: {
            url: 'update-notif-favevents'
        },
        addMediaMultiply: {
            url: 'add-media-multiply'
        },
        userMedia: {
            url: 'media'
        },
        userUpdateAvatar: {
            url: 'update-avatar'
        }


    }

} as const;
