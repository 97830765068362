import {SET_IS_LOGGED_IN, SET_USER_DATA, UPDATE_REGISTER_FIELD} from '../actions/actionTypes';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
  role: null,
  userData: null,
  isLoggedIn: false,
};

const sessionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_REGISTER_FIELD:
      return {...state, [action.payload.field]: action.payload.value};
    case SET_USER_DATA:
      return {...state, userData: action.payload};
    case SET_IS_LOGGED_IN:
      return {...state, isLoggedIn: action.payload, ...(action.payload === false && {userData: null})};
    default:
      return state;
  }
};

export default sessionReducer;
