import {
  SET_CATEGORIES,
  SET_EVENTS,
  SET_USER_DEVICE_TOKEN,
  SET_USER_LOCATION,
  SET_USER_PETS
} from '../actions/actionTypes';
import {IGlobalState} from "../../types";

const initialState:IGlobalState = {
  categories: [],
  pets: [],
  events: [],
  location: {
    timestamp:0
  },
  deviceToken: '',
};



const globalReducer = (state:IGlobalState = initialState, action: any) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {...state, categories: action.payload};
    case SET_USER_PETS:
      return {...state, pets: action.payload};
    case SET_EVENTS:
      return {...state, events: action.payload};
    case SET_USER_LOCATION:
      return {...state, location: action.payload};
    case SET_USER_DEVICE_TOKEN:
      return {...state, deviceToken: action.payload};
    default:
      return state;
  }
};

export default globalReducer;
