import * as React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import Login from '../screens/Auth/Login/Login';
import ForgotPassword from '../screens/Auth/ForgotPassword/ForgotPassword';
import Registration from '../screens/Auth/Registration/Registration';
import RoleSelection from '../screens/Auth/RoleSelection/RoleSelection';
import RoleAddress from '../screens/Auth/RoleAddress/RoleAddress';
import Start from '../screens/Auth/Start/Start';
import {AuthStackParamList} from '../types';
const Auth = createStackNavigator<AuthStackParamList>();

function AuthNavigator() {
  const options = {
    gestureEnabled: false,
  };
  return (
    <Auth.Navigator screenOptions={{headerShown: false, presentation: 'card'}}>
      <Auth.Screen name="Start" component={Start} options={options} />
      <Auth.Screen name="Login" component={Login} options={options} />
      <Auth.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={options}
      />
      <Auth.Screen
        name="Registration"
        component={Registration}
        options={options}
      />
      <Auth.Screen
        name="RoleSelection"
        component={RoleSelection}
        options={options}
      />
      <Auth.Screen
        name="RoleAddress"
        component={RoleAddress}
        options={options}
      />
    </Auth.Navigator>
  );
}

export default AuthNavigator;
