import React, {FC} from 'react';
import {IPet} from "../../../types";
import {FlatList, Image, ListRenderItem, ListRenderItemInfo, Text, View} from "react-native";
import {styles as stylesProfile} from "../Profile/styles";

import {getMediaFirst, getMediaPreview} from "../../../utils/common";
import {capitalizeFirstLetter} from "../../../utils/text";
import {translate} from "../../../utils/translate";
import styles from "./styles";
import {SCREEN_WIDTH} from "../../../constants/globalStyles";

interface PetsListProps {
    pets: IPet[],
    nestedScrollEnabled: boolean
}

const PetsList:FC<PetsListProps> = ( {pets, nestedScrollEnabled= false, ...props}) => {
    const renderItem = ({item}:ListRenderItemInfo<IPet>) => (
        <View key={`pet_${item.id}`} >
            <View style={[stylesProfile.sectionContainer,{width:SCREEN_WIDTH}]}>
                <View>
                    <Image style={[stylesProfile.sectionObjectImage,{resizeMode: "contain"}]} source={{uri:getMediaPreview(getMediaFirst(item.media))}} />
                </View>
                <View style={stylesProfile.sectionTextContainer}>
                    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={stylesProfile.sectionText}>
                            {capitalizeFirstLetter(translate(`pet.type_${item.type}`))}:
                            {' '}
                            {capitalizeFirstLetter( item.name != null ? item.name: capitalizeFirstLetter(translate("pet.noname")))},
                            {' '}
                            {item.age}{translate("pet.yearsBrief")}
                        </Text>
                        <Text style={stylesProfile.sectionText}>
                            {capitalizeFirstLetter(translate('pet.chip'))}:
                            {capitalizeFirstLetter( item.chip != null ? translate('common.yes'): translate('common.no') )}</Text>
                    </View>
                    <View>
                        <Text style={{marginTop: 5, marginBottom: 5, fontSize: 14, lineHeight: 17, fontWeight: "400"}}>
                            {capitalizeFirstLetter(translate("common.color"))}: {item.color}
                        </Text>
                        <Text style={stylesProfile.sectionText}>
                            {capitalizeFirstLetter(translate("common.description"))}: {item.description}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );

    return (
        <View style={styles.container}>
            <FlatList
                showsHorizontalScrollIndicator={true}
                horizontal
                data={pets}
                contentContainerStyle={styles.listContainer}
                renderItem={renderItem}
                nestedScrollEnabled
            />
        </View>
    );
};

export default PetsList;
