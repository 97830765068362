import {IAPI_PETS_MODEL} from "./types";


export const API_PETS_MODEl:IAPI_PETS_MODEL = {
    entity: 'pet',
    url: 'pet',
    methods: {
        getPetsByUserId: {
            url: 'get-pets-by-user-id'
        },
        createPet: {
            url: 'pets'
        },
        getPetTypes: {
            url: 'get-types'
        },
        addPetMediaMultiply: {
            url: 'add-media-multiply'
        }
    }

} as const;
