import {IAPI_EVENTS_MODEL} from "./types";
import {createEvent} from "./createEvent/createEvent";

export const API_EVENTS_MODEL:IAPI_EVENTS_MODEL = {
    entity: 'event',
    url: 'event',
    methods: {
        getEvents: {
            url: 'get-events'
        },
        getEventsFav: {
            url: 'get-user-fav-events'
        },
        getEventsByUser: {
            url: 'get-events-by-user'
        },
        getEventComments: {
            url: 'get-comments'
        },
        addCommentToEvent: {
            url: 'add-comment-to-event'
        },
        addEventImage: {
            url: 'add-event-image'
        },
        addEventMediaMultiply: {
            url: 'add-media-multiply'
        },
        createEvent: {
            url: 'events'
        },
        getEventsByUniSearch: {
            url: 'get-events-by-uni-search'
        }
    }

} as const;
