import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  listEvent: {},
  flatlistColumn: {
    justifyContent: 'space-between',
  },
  separator:{
    width:10,
  }
});

export default styles;
