/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, {useEffect} from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import AppNavigation from './src/navigation';
import * as RNLocalize from 'react-native-localize';
import {persister, store} from './src/redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {setI18nConfig} from "./src/utils/translate";
import FlashMessage from "react-native-flash-message";

import {LogBox} from 'react-native';

LogBox.ignoreLogs([
    "[react-native-gesture-handler] Seems like you\'re using an old API with gesture components, check out new Gestures system!",
    'new NativeEventEmitter',
    'EventEmitter.removeListener',
    'VirtualizedLists should never be nested',
    'Animated: `useNativeDriver`',
    'Remote debugger is in a background tab which may cause apps to perform slowly. Fix this by foregrounding the tab (or opening it in a separate window).'
]);


export default function App() {
    const handleLocalizationChange = () => {
        setI18nConfig()
            .then(() => this.forceUpdate())
            .catch(error => {
                console.error(error);
            });
    };

    useEffect(() => {
        setI18nConfig();
        RNLocalize.addEventListener('change', handleLocalizationChange);

        return () => {
            RNLocalize.removeEventListener('change', handleLocalizationChange);
        };
    }, []);


    useEffect(() => {

        /*
                GetLocation.getCurrentPosition({
                    enableHighAccuracy: true,
                    timeout: 15000,
                })
                    .then(location => {
                        console.log('LOCATION', location);
                    })
                    .catch(error => {
                        const { code, message } = error;
                        console.warn(code, message);
                    })

         */
    }, [])


    return (
        <SafeAreaProvider>
            <Provider store={store}>
                <PersistGate  loading={false} persistor={persister}>
                    <AppNavigation />
                    <FlashMessage position="bottom" duration={2750} floating/>
                </PersistGate>
            </Provider>
        </SafeAreaProvider>
    );
}
