import React, {FC} from 'react';
import {Image, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {colors} from '../../../constants/Colors';
import styles from './styles';
import {translate} from "../../../utils/translate";


interface IInputStyles {
    inputSection?: object;
    tinyLogoRight?: object;
    input?:object;
}

interface Props {
  onChange: any;
    onPress?: any;
  placeHolderText: string;
  valueText?: string;
  isShowIcon?: boolean;
  icon?: any;
  type?: any;
  isShowRightIcon?: boolean;
  rightIcon?: any;
  isPassword?: boolean;
  extraStyles?: IInputStyles
    inputRef?: any;
};

const Input:FC<Props> = ({
                           onChange,
                             onPress,
                             inputRef,
                           placeHolderText,
                           valueText,
                           type = 'none',
                           icon,
                           isShowIcon = false,
                           isShowRightIcon = false,
                           rightIcon,
                           isPassword = false,
                           extraStyles= {
                               inputSection: {},
                               tinyLogoRight: {},
                               input: {}
                           },
                           ...props
                         }) => {

  return (
      <View style={[styles.inputSection, extraStyles?.inputSection]}>
        {isShowIcon && <Image style={styles.tinyLogo} source={icon} />}
        <TextInput
            style={[styles.input,extraStyles?.input]}
            onChangeText={onChange}
            value={valueText}
            placeholder={placeHolderText}
            textContentType={type}
            placeholderTextColor={colors.silver}
            secureTextEntry={isPassword}
            ref = {inputRef}
        />
        {isShowRightIcon && (
            <TouchableOpacity onPress={onPress}>
              <Image
                  style={[styles.tinyLogo, styles.tinyLogoRight, extraStyles.tinyLogoRight]}
                  source={rightIcon}
              />
            </TouchableOpacity>
        )}
      </View>
  );
};

export default Input;
