import React, {FC} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import ViewScreen from "../../../components/Project/ViewScreen/ViewScreen";
import {IScreen} from "../../../types";
import BackButton from "../../../components/UI/BackButton/BackButton";
import {translate} from "../../../utils/translate";
import {styles} from "../../../components/Project/Profile/styles";
import {fonts} from "../../../constants/fonts";
import {colors} from "../../../constants/Colors";
import {SCREENS} from "../../../navigation/screenName";

const stylesScreen = StyleSheet.create({
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        height: 47,
        width: '100%',
    },
    text: {
        color: colors.black,
        fontSize: 18,
        lineHeight: 22.5,
        fontFamily: fonts.Medium,
        flex: 1,
    },
});
const ProfileSettings:FC<IScreen> = ({navigation, route}) => {
    return (
        <ViewScreen>
            <View style={styles.background}>
                <View style={styles.profile}>
                    <View style={{position: 'absolute', left: 0}}>
                        <BackButton
                            text={translate('back')}
                            action={() => {
                                navigation.goBack()
                            }}
                        />
                    </View>
                    <View style={styles.profileHeader}>
                        <View style={{width: "100%", justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{
                                fontSize: 18,
                                color: "#392413",
                                lineHeight: 22.5,
                                fontWeight: "500"
                            }}>{translate('profile.settings')}</Text>
                        </View>

                        <View style={styles.profileSettingsContainer}>
                            <View style={[stylesScreen.container, {marginTop: 10}]}>
                                <TouchableOpacity onPress={ () => { navigation.navigate(SCREENS.ProfileNotifications) } }>
                                    <Text style={stylesScreen.text}>{translate('profile.notifications')}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={stylesScreen.container}>
                                <TouchableOpacity onPress={ () => { navigation.navigate(SCREENS.ProfileApp) } }>
                                    <Text style={stylesScreen.text}>{translate('profile.appAbout')}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={stylesScreen.container}>
                                <TouchableOpacity onPress={ () => { navigation.navigate(SCREENS.ProfilePassword) } }>
                                    <Text style={stylesScreen.text}>{translate('profile.changePassword')}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </ViewScreen>
    );
};

export default ProfileSettings;
