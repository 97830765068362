import {IRegistrationFormData} from "./types";

export const defaultRegData:IRegistrationFormData = {
    name:'',
    first_name: '',
    last_name:'',
    email:'',
    phone:'',
    password:'',
    password_confirmation:'',
    role:1,
    ur_fio:'',
    ur_name: '',
    ur_description: '',
    ur_ogrn: '',
    ur_kpp: '',
    ur_bik: '',
    ur_rekv: '',
    ur_address: '',
    ur_passport: '',
    ur_photo: '',
    website: '',
    description: '',
    ur_legalname: '',
    ur_factaddress: '',
    ur_inn:'',
    ur_type: 0

}
