import React, {FC, useEffect, useState} from 'react'
import {Image, SafeAreaView, Text, TouchableOpacity, View} from 'react-native'
//@ts-ignore
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import {styles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {getUserById} from "../../../api/user/getUserById/getUserById";
import ButtonSaveEvent from "../ButtonSaveEvent/ButtonSaveEvent";
import {translate} from "../../../utils/translate";
import {setIsLoggedIn} from "../../../redux/AuthRedux/actions/actionCreator";
import {getFullDate} from "../../../utils/datetime";
import {capitalizeFirstLetter} from "../../../utils/text";
import {getMediaFirst, getMediaPreview} from "../../../utils/common";
import {useIsFocused} from "@react-navigation/native";
import ProfileRating from "./ProfileRating/ProfileRating";

import PetsList from "../PetsList/PetsList";
import {SCREENS} from "../../../navigation/screenName";
import FeedAddModal from "../FeedAddModal/FeedAddModal";
import FeedList from "../FeedList/FeedList";
import ButtonAdd from "../ButtonAdd/ButtonAdd";
import {getEventsByUniSearch} from "../../../api/events/getEventsByUniSearch/getEventsByUniSearch";
import {getEventsByUser} from "../../../api/events/getEventsByUser/getEventsByUser";
import {getEvents} from "../../../api/events/getEvents";
import {NAVIGATORS} from "../../../navigation/const";

interface IProfileComponent {
    user_id?: number | undefined;
    initialSection?: 'profile' | 'reviews' | 'settings';
    navigation: any
}

const colorStarFull = "#8AC43A";
const colorStarEmpty = "grey";

const Profile:FC<IProfileComponent> = ({
                                           user_id,
                                           initialSection= 'profile',
                                           navigation
                                       }) => {
    const isFocused = useIsFocused();
    const dispatch = useDispatch();

    const [modalFeedVisible, setModalFeedVisible] = useState(false);
    const [profileInfo, setProfileInfo] = useState<any>({});
    const [isLoaded, setIsLoaded] = useState<any>(false);
    const [events, setEvents] = useState<any[]>([]);
    const [pets, setPets] = useState<any[]>([]);
    const [profileUserId, setProfileUserId] = useState<number|undefined>(user_id)
    const [displaySection, setDisplaySection] = useState(initialSection);
    const [notifSettings, setNotifSettings] = useState(false);
    const [regionValue, setRegionValue] = useState("");

    const userInfo = useSelector((state: any) => state?.session?.userData);
    const onEventPress = (id:any) => {
        navigation.navigate('CommonNavigator', { screen: 'EventScreen', params: {eventId:id } } );
    }

    const onPetPress = (id:any) => {
        console.log(id);
    }


    const onLogout = () => {
        dispatch(setIsLoggedIn(false));
    }

    useEffect(() => {

        if(isFocused) {
            if (user_id == undefined || user_id == userInfo.user_id) {
                user_id = userInfo.user_id;

                setProfileUserId(user_id);
            }

            //TODO: заменить на получение пользователя
            getUserById({user_id: user_id}).then((dataUser) => {
                setProfileInfo(dataUser);
                setPets(dataUser.pets);
                //setEvents(dataUser.events);
                setNotifSettings(dataUser.notification == 1 ? true:false);
                setRegionValue("Москва");
                setIsLoaded(true);
            });
            getEvents({user_id:user_id}).then( (dataEvents) => {
                setEvents(dataEvents);
            });
        }
    }, [isFocused]);

    const toggleFeedModal = () => {
        setModalFeedVisible(!modalFeedVisible);
    }

    const navigateToAddPets = () => {
        navigation.navigate('CommonNavigator', {screen: SCREENS.ProfilePetForm})
    }

    return (
        <View style={[styles.background]}>
            <FeedAddModal isVisible={modalFeedVisible} toggleModal={toggleFeedModal}/>

            {isLoaded && displaySection == "profile" &&
            <View style={styles.profile}>
                {/*TODO:: Вынести хидер в отдельный компонент*/}
                <View style={styles.profileHeader}>
                    <View style={{width: "100%", justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={{fontSize: 18, color: "#392413", lineHeight: 22.5, fontWeight: "500"}}>{translate('profile.title')}</Text>
                        {profileUserId && profileUserId === userInfo?.user_id &&
                            <View style={{flexDirection:"row", position: 'absolute', right: 0, justifyContent: "flex-end"}}>
                                <TouchableOpacity onPress={() => {
                                    //@ts-ignore
                                    navigation.navigate('CommonNavigator', {screen: 'ProfileFormScreen'});
                                }}>
                                    <Icon name="pencil" size={24}/>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => {
                                    //@ts-ignore
                                    navigation.navigate('ProfileSettings');
                                }} >
                                    <Icon name="cog" size={24}/>
                                </TouchableOpacity>

                            </View>
                        }
                    </View>
                    <View style={[styles.profileInfoContainer,{flex:1}]}>
                        <View>
                            {profileInfo.role == 1
                                ?
                                    <Text style={[styles.bodySectionTitle]}>{profileInfo.full_name}</Text>
                                :
                                <>
                                    <Text style={styles.bodySectionTitle}>{profileInfo.ur_name}</Text>
                                    <Text >{profileInfo.ur_legalname}</Text>
                                </>
                            }
                            <ProfileRating profileId={profileInfo.id} profileInfoProp={profileInfo} />
                            {profileInfo.role == 1
                                ?
                                <View style={styles.alignProfileInfo}>
                                    <Text style={styles.profileRegisterInfo}>{translate(`profile.role_${profileInfo.role}`)},</Text>
                                    <Text style={styles.profileRegisterInfo}>{translate('profile.created_at')} {getFullDate(profileInfo.created_at)}</Text>
                                </View>
                                :
                                <>
                                    <View style={styles.alignProfileInfoNoRow}>
                                        <Text style={styles.profileRegisterInfo}>Адрес {profileInfo.ur_factaddress}</Text>
                                    </View>
                                    <View style={styles.alignProfileInfo}>
                                        <Text style={styles.profileRegisterInfo}>Сайт {profileInfo.website}</Text>
                                        <Text style={styles.profileRegisterInfo}>Телефон {profileInfo.phone}</Text>
                                    </View>
                                </>
                            }
                        </View>
                        <View>
                            <Image style={styles.profileImage} source={{uri:getMediaPreview(getMediaFirst(profileInfo.media))}} />
                        </View>
                    </View>
                </View>

                <View style={styles.profileBody}>
                    {profileInfo.role != 1 &&
                    <View>
                        <Text style={styles.profileRegisterInfo}>
                            {profileInfo.description}
                        </Text>
                    </View>
                    }
                    <View>
                        {profileInfo && profileInfo.pets && profileInfo.role != 3 &&
                        <View>
                            <View style={{flexDirection: 'row', alignItems: "center"}}>
                                {profileUserId && profileUserId === userInfo?.user_id &&
                                <ButtonAdd buttonSize={32} onPress={ navigateToAddPets } />
                                }
                                <View>
                                    <Text style={styles.bodySectionTitle}>{capitalizeFirstLetter(translate('pet.pets'))}</Text>
                                </View>

                                <View style={{justifyContent: "flex-end"}}>

                                </View>

                            </View>
                            <PetsList nestedScrollEnabled pets={profileInfo.pets} />
                        </View>
                        }
                        {events && events.length > 0 &&
                        <>
                            <View style={{flexDirection: 'row', alignItems: "center"}}>
                                {profileUserId && profileUserId === userInfo?.user_id &&
                                <ButtonAdd buttonSize={32} onPress={ toggleFeedModal } />
                                }
                                <View>
                                    <Text style={styles.bodySectionTitle}>{capitalizeFirstLetter(translate('event.feed'))}</Text>
                                </View>
                                {profileUserId && profileUserId === userInfo?.user_id &&
                                <View style={{justifyContent: "flex-end"}}>

                                </View>
                                }
                            </View>

                            <FeedList nestedScrollEnabled data={events} onItemPress={onEventPress}/>

                            {/*profileInfo.events.map( (event:IEvent) =>
                                //@ts-ignore
                                <TouchableOpacity key={`event_${event.id}`} onPress={ () => { onEventPress(event.id)} }>
                                    <View style={[styles.sectionContainer, {width:"33%"}]}>
                                        <View>
                                            <Image style={[styles.sectionObjectImage, {resizeMode: "contain"}]} source={{uri:getMediaPreview(getMediaFirst(event.media))}}/>
                                        </View>

                                        <View style={styles.sectionTextContainer}>
                                            <Text style={{fontSize: 14, fontWeight: "700", lineHeight: 17.5}}>{event.evt_topic}</Text>
                                            <View style={{flexDirection: 'row', marginTop: 5, marginBottom: 5}}>
                                                <Icon name="clock-time-five-outline" size={15} style={{color: "#8AC43A"}}/>
                                                <Text style={{fontSize: 12, lineHeight: 15, marginLeft: 5}}>{datetimeConvert(event.evt_dt_added)}</Text>
                                            </View>
                                            <View style={{flexDirection: 'row'}}>
                                                <Icon name="map-marker-outline" size={15} style={{color: "#8AC43A"}}/>
                                                <Text style={{fontSize: 12, lineHeight: 15, marginLeft: 5}}>{event.evt_address}</Text>
                                            </View>
                                        </View>

                                    </View>
                                </TouchableOpacity>
                            )*/}
                        </>
                        }
                    </View>
                </View>

                {/*profileUserId && profileUserId != userInfo?.user_id &&
                <View style={{padding:5}}>
                    <ButtonSaveEvent
                        buttonText={translate("chat.send")}
                        onPress={() => {} }
                    />

                        <TouchableOpacity style={styles.messageButton}>
                            <Text style={{fontSize: 16, fontWeight: "700", color: "#FFFFFF", lineHeight: 19}}>MESSAGE
                                ELISABETH</Text>
                        </TouchableOpacity>

                </View>
                */}

            </View>
            }

        </View>
    )
}

export default Profile

