import React, {FC} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {translate} from '../../../utils/translate';
import styles from './styles';

type Props = {
  onPress: () => void;
  buttonText: string;
  style?: {};
  textStyle?: {};
};

const ButtonSaveEvent: FC<Props> = ({onPress, buttonText, ...props}) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.container, props.style]}>
      <Text style={[styles.buttonText, props.textStyle]}>{buttonText}</Text>
    </TouchableOpacity>
  );
};

export default ButtonSaveEvent;
