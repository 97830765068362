import {translate} from '../utils/translate';
import EventScreen from "../screens/Event/EventScreen/EventScreen";
import Favorites from "../screens/Favorites/Favorites";
import PostFormScreen from "../screens/Event/PostFormScreen/PostFormScreen";

interface IScreenNames {
  [x:string]: string
}


export const SCREENS: any = {
  Home: translate('nav.home'),
  Favorites: translate('nav.favorites'),

  Map: translate('nav.map'),
  Messages: translate('nav.messages'),
  Profile: translate('nav.profile'),
  AddEvent: 'AddEvent',

  HomeScreen: 'ЗооКлик - Лента',
  HomeScreenEvent: 'HomeScreenEvent',

  FavoritesScreen: 'FavoritesScreen',
  FavoritesScreenEvent: 'FavoritesScreenEvent',

  ProfileUserScreen: 'ProfileUserScreen',
  ProfileApp: 'ProfileApp',
  ProfileNotifications: 'ProfileNotifications',
  ProfileSettings: 'ProfileSettings',
  ProfileReviews: 'ProfileReviews',
  ProfilePassword: 'ProfilePassword',
  ProfileReviewsScreen: 'ProfileReviewsScreen',

  ProfilePetForm: 'ProfilePetForm',
  PostFormScreen: 'PostFormScreen',
  ProfileFormScreen: 'ProfileFormScreen',


/*
  FavoritesStack: {
    Favorites: {
      name: 'FavoritesStack',
      title: translate('nav.favorites'),
      component: Favorites,
    },
    Event: {
      name: 'FavoritesEvent',
      title: 'Event',
      component: EventScreen
    }
  },

  Event: {
    Form: 'Add Event',
    Screen: {
      name: 'EventScreen',
      title: 'Event',
      component: EventScreen
    },
  }

 */
};
