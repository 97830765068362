import React, {FC, useEffect, useState} from 'react';
import {Image, Platform, ScrollView, TextInput, View} from 'react-native';
import {colors} from '../../../constants/Colors';
import {translate} from '../../../utils/translate';
import styles from './styles';
import {defaultImageUri} from '../../../constants/images';
import ButtonSaveEvent from '../ButtonSaveEvent/ButtonSaveEvent';
import DropDownPicker from "react-native-dropdown-picker";
import {IEventOnFieldChange} from "../EventForm/types";
import {capitalizeFirstLetter} from "../../../utils/text";
import {getPetTypes} from "../../../api/pets/getPetTypes/getPetTypes";
import {getFormData} from "../../../utils/formData";
import {createPet} from "../../../api/pets/createPet/createPet";
import {showMessage} from "react-native-flash-message";
import {IPetFormProps} from "./types";
import DocumentPicker from "react-native-document-picker";
import MediaAddList from "../../UI/MediaAddList/MediaAddList";
import {addPetMediaMultiply} from "../../../api/pets/addPetMediaMultiply/addPetMediaMultiply";
import {responseWithBadWords} from "../../../utils/response";
import {Dictionary} from "../../../locales/dictionary";


const PetForm:FC<IPetFormProps> = ({navigation, route, ...props}) => {
    const [medias, setMedias] = useState<any[]>([]);
    const [formPetId, setFormPetId] = useState(false);


    const [openPetTypesList, setOpenPetTypesList] =  useState<boolean>(false);
    const [petTypesList, setPetTypesList] =  useState<any[]>([]);
    const [petTypesHash, setPetTypesHash] = useState<any>({});
    const [petTypeValue, setPetTypeValue] = useState<number>(0);

    const [petSexValue, setPetSexValue] = useState<number>(-1);
    const [openPetSexList, setOpenPetSexList] =  useState<boolean>(false);
    const [petSexList, setPetSexList] =  useState<any[]>([]);

    const [petSterValue, setPetSterValue] = useState<number>(-1);
    const [openPetSterList, setOpenPetSterList] =  useState<boolean>(false);
    const [petSterList, setPetSterList] =  useState<any[]>([]);

    const [fieldsPetData, setPetFieldsData] = useState({
        type_id: 0,
        age: 0,
        color: "",
        chip: "",
        description: "",
        images: [],
        address: "",
        contact_details: "",
        sex:0,
        name:'',
        species: '',
        sterilization: 0,
    });

    const onFieldChange = ({form = 'pet', field, data}:IEventOnFieldChange) => {
        let tmpFieldsData = {...fieldsPetData};
        //@ts-ignore
        tmpFieldsData[field] = data;
        setPetFieldsData(tmpFieldsData)
    }

    useEffect( () => {
        getPetTypesList();
        setPetSexList([
            {
                label: translate('pet.sex_m'),
                value: 0
            },
            {
                label: translate('pet.sex_f'),
                value: 1
            }
        ]);
        setPetSterList([
            {
                label: translate('pet.ster_no'),
                value: 0
            },
            {
                label: translate('pet.ster_yes'),
                value: 1
            }
        ]);
    }, []);

    const getPetTypesList = () => {
        getPetTypes().then( resp => {
            let tmpHash:any = {};
            let tmpList:any[] = [];
            resp.map( (petType:any) => {
                tmpHash[petType.id] = petType;
                tmpList.push({
                    label: translate(`pet.type_${petType.type_name}`),
                    value: petType.id
                });
            });
            setPetTypesHash(tmpHash);
            setPetTypesList(tmpList);
        });
    }

    const onChangeMedias = (data:any[]) => {
        setMedias(data);
    }

    const onSubmitForm = async () => {
        const petData = {
            ...fieldsPetData,
            type_id: petTypeValue,
            sex: petSexValue,
            sterilization: petSterValue,
        }

        await addPet(petData);
    }

    const addPetImages = async (eventId: any) => {
        let imageForm = new FormData();
        imageForm.append("pet_id", eventId);

        medias.map( async (item, index) => {
            imageForm.append(`media_files[${index}]`,{
                name: item.name,
                type: item.type,
                uri: Platform.OS === 'ios' ? item.uri.replace('file://', '') : item.uri,
            });
        })
        const response = await addPetMediaMultiply(imageForm);
        if (response.success) {
            /*
            showMessage({
                message: response.message,
                type: "success"
            });

             */
        } else {
            showMessage({
                message: translate("errors." + response.message),
                type: "danger"
            });
        }
        return response;
    }

    const addPet = async( petData:any ) => {
        const petFormData = getFormData(petData);
        let resp:any = {success:false, data: formPetId, message: 'unknownError'};
        resp = await createPet(petFormData);
        console.log(resp.message);
        /*
        if(formPetId === false) {
            resp = await createPet(petFormData);
            //setFormPetId(resp.data);
        } else {
            //TODO:: update
            resp = {success:true, data: formPetId}
        }
*/
        responseWithBadWords({
            resp: resp,
            messageSuccess: Dictionary.pet.addSuccess,
            messageBadWords: Dictionary.errors.badWords,
            messageError: "errors." + resp.message,
            callBackSuccess: () => {
                addPetImages(resp.data).then(petImagesResp => {
                    if(petImagesResp.success) {
                        showMessage({
                            message: translate("pet.addSuccess"),
                            type: "success"
                        });
                        navigation.goBack();
                    } else {
                        showMessage({
                            message: [translate("pet.addError"), translate('errors.' + petImagesResp.message)].join('. '),
                            type: "danger"
                        });
                        return 0;
                    }
                });
            }
        })
    }

    return (
        <ScrollView style={styles.container}>
            <View style={styles.formLayout}>
                <View style={styles.leftLayout}>
                    <DropDownPicker
                        open={openPetTypesList}
                        setOpen={setOpenPetTypesList}
                        value={petTypeValue}
                        items={petTypesList}

                        //@ts-ignore: Unreachable code error
                        setValue={setPetTypeValue}
                        listMode="SCROLLVIEW"
                        placeholder={translate('pet.type_id')}
                        placeholderStyle={{
                            color: 'gray',
                            // fontWeight: "bold"
                        }}
                        dropDownDirection="AUTO"
                        style={[styles.dropdown]}
                        dropDownContainerStyle={styles.dropdownContainer}
                    />

                    <TextInput
                        placeholder={translate('age')}
                        placeholderTextColor={colors.halfCedar}
                        style={[styles.input]}
                        onChangeText={(data) => {
                            onFieldChange({form: 'pet', field: 'age', data: data})
                        }}
                    />
                </View>
                <View style={styles.rightLayout}>
                    <Image source={{uri: (medias[0]) ? medias[0].uri:defaultImageUri}} style={[styles.image]} />
                </View>
            </View>

            <View style={styles.textInputsRow}>
                <View style={styles.halfInput}>
                    <DropDownPicker
                        open={openPetSexList}
                        setOpen={setOpenPetSexList}
                        value={petSexValue}
                        items={petSexList}

                        //@ts-ignore: Unreachable code error
                        setValue={setPetSexValue}
                        listMode="SCROLLVIEW"
                        placeholder={translate('pet.sex')}
                        placeholderStyle={{
                            color: 'gray',
                            // fontWeight: "bold"
                        }}
                        dropDownDirection="AUTO"
                        style={[styles.dropdown]}
                        dropDownContainerStyle={styles.dropdownContainer}
                    />
                </View>
                <TextInput
                    placeholder={translate('color')}
                    placeholderTextColor={colors.halfCedar}
                    style={[styles.input, styles.halfInput]}
                    onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'color', data: data})
                    }}
                />
            </View>
            <View style={styles.textInputsRow}>
                <TextInput
                    placeholder={translate('pet.species')}
                    placeholderTextColor={colors.halfCedar}
                    style={[styles.input, styles.halfInput]}

                    onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'species', data: data})
                    }}
                />

                <TextInput
                    placeholder={translate('pet.name')}
                    placeholderTextColor={colors.halfCedar}
                    style={[styles.input, styles.halfInput]}
                    onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'name', data: data})
                    }}
                />
            </View>
            <View style={styles.textInputsRow}>
                <TextInput
                    placeholder={translate('chip')}
                    placeholderTextColor={colors.halfCedar}
                    style={[styles.input, styles.halfInput]}
                    onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'chip', data: data})
                    }}
                />
                <View style={styles.halfInput}>
                    <DropDownPicker
                        open={openPetSterList}
                        setOpen={setOpenPetSterList}
                        value={petSterValue}
                        items={petSterList}

                        //@ts-ignore: Unreachable code error
                        setValue={setPetSterValue}
                        listMode="SCROLLVIEW"
                        placeholder={translate('pet.ster')}
                        placeholderStyle={{
                            color: 'gray',
                            // fontWeight: "bold"
                        }}
                        dropDownDirection="AUTO"
                        style={[styles.dropdown]}
                        dropDownContainerStyle={styles.dropdownContainer}
                    />
                </View>
            </View>

            <View style={styles.bottomLayout}>
                <TextInput
                    placeholder={capitalizeFirstLetter(translate('common.address'))}
                    placeholderTextColor={colors.halfCedar}
                    style={styles.bigInput}
                    onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'address', data: data})
                    }}
                />

                <TextInput
                    placeholder={capitalizeFirstLetter(translate('common.contacts'))}
                    placeholderTextColor={colors.halfCedar}
                    style={styles.bigInput}
                    onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'contact_details', data: data})
                    }}
                />
                <TextInput
                    placeholder={translate('description')}
                    placeholderTextColor={colors.halfCedar}
                    style={styles.bigInput}
                    onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'description', data: data})
                    }}
                />
                <MediaAddList medias={medias} onChangeMedias={onChangeMedias} />

                <ButtonSaveEvent
                    onPress={onSubmitForm}
                    buttonText={translate('pet.addPet')}
                    style={styles.submitButton}
                />
            </View>
        </ScrollView>
    );
};
export default PetForm;
