import React from "react";
import {createStackNavigator} from "@react-navigation/stack";
import {CommonNavigatorParamList} from "./types";
import {NAVIGATORS} from "../const";
import EventScreen from "../../screens/Event/EventScreen/EventScreen";
import EventFormScreen from "../../screens/Event/EventFormScreen/EventFormScreen";
import ProfileUserScreen from "../../screens/Profile/ProfileUserScreen/ProfileUserScreen";
import ChatScreen from "../../screens/Message/ChatScreen/ChatScreen";
import {SCREENS} from "../screenName";
import ProfileReviewsScreen from "../../screens/Profile/ProfileReviewsScreen/ProfileReviewsScreen";
import ProfilePetForm from "../../screens/Profile/ProfilePetForm/ProfilePetForm";
import PostFormScreen from "../../screens/Event/PostFormScreen/PostFormScreen";
import ProfileFormScreen from "../../screens/Profile/ProfileFormScreen/ProfileFormScreen";

const CommonStack = createStackNavigator<CommonNavigatorParamList>();

function CommonNavigator() {

    return (
        <CommonStack.Navigator >
            <CommonStack.Screen name={'EventScreen'} component={EventScreen} options={{headerShown: false} } />
            <CommonStack.Screen name={'EventFormScreen'} component={EventFormScreen} options={{headerShown: false} } />
            <CommonStack.Screen name={'ProfileUserScreen'} component={ProfileUserScreen} options={{headerShown: false} } />
            <CommonStack.Screen name={'ChatScreen'} component={ChatScreen} options={{headerShown: false} } />
            <CommonStack.Screen name={SCREENS.ProfileReviewsScreen} component={ProfileReviewsScreen} options={{headerShown: false} } />
            <CommonStack.Screen name={SCREENS.ProfilePetForm} component={ProfilePetForm} options={{headerShown: false} } />
            <CommonStack.Screen name={SCREENS.PostFormScreen} component={PostFormScreen} options={{headerShown: false} } />
            <CommonStack.Screen name={'ProfileFormScreen'} component={ProfileFormScreen} options={{headerShown: false} } />
        </CommonStack.Navigator>
    )
}

export default CommonNavigator;
