import React, {useCallback, useEffect, useState} from 'react';
import {DarkTheme, DefaultTheme, NavigationContainer, useNavigation,} from '@react-navigation/native';
import {createStackNavigator, TransitionPresets} from '@react-navigation/stack';
import {RootStackParamList} from '../types';
import AuthNavigator from './AuthNavigator';
import BottomTabNavigator from './BottomTabNavigator';
import {useDispatch, useSelector} from 'react-redux';
import CommonNavigator from "./CommonNavigator/CommonNavigator";
import Geolocation from "@react-native-community/geolocation";
import {setDeviceToken, setUserLocation} from "../redux/GlobalRedux/actions/actionCreator";
//import messaging from '@react-native-firebase/messaging';
//import {addFirebaseToken} from "../api/user/addFirebaseToken/addFirebaseToken";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {addGeo} from "../api/user/addGeo/addGeo";
import {PermissionsAndroid} from "react-native";

type ColorSchemeName = 'light' | 'dark' | null | undefined;

export default function AppNavigation({
                                          colorScheme,
                                      }: {
    colorScheme: ColorSchemeName;
}) {

    return (
        <NavigationContainer
            theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
            <RootNavigator />
        </NavigationContainer>
    );
}

const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
    const isLoggedIn = useSelector((state: any) => state?.session?.isLoggedIn);
    const location = useSelector((state: any) => state?.global?.location)
    const [currLoc, setCurrLoc] = useState<any>({
        isPosition:false,
        timestamp: 0
    });

    const [localState, setLocalState] = useState<{[x:string]: any}>({
        currentPosition: {isPosition:false, timestamp: 0},
        positionWatch: null,
        locationPermission: false,
    })

    // If you want to swipe back like iOS on Android
    const options = {
        gestureEnabled: false,
        ...TransitionPresets.SlideFromRightIOS,
    };
    const optionsSlide = {
        gestureEnabled: false,
        ...TransitionPresets.ModalSlideFromBottomIOS,
    };


    const dispatch = useDispatch();
    const navigation = useNavigation();

    useEffect( () => {
        /*
        requestLocationPermissions().then( resp => setLocalState({...localState, locationPermission: resp}) );
        const fbaseSetBackgroundMessageHandler = messaging().setBackgroundMessageHandler(() => Promise.resolve());
        const firebaseMessageOpened = messaging().onNotificationOpenedApp(remoteMessage => {

            //@ts-ignore
            //navigation.navigate('CommonNavigator', { screen: 'EventScreen', params: {eventId: remoteMessage?.data?.object_id } } );
            firebaseNavigate(remoteMessage, 'onNotificationOpenedApp')
        });

        // Check whether an initial notification is available
        const firebaseMessageInitial = messaging().getInitialNotification().then(remoteMessage => {
            //const navigation = useNavigation();
            //@ts-ignore
            //navigation.navigate('CommonNavigator', { screen: 'EventScreen', params: {eventId: remoteMessage?.data?.object_id } } );
            firebaseNavigate(remoteMessage, 'getInitialNotification')
        });

        const firebaseOnMessages = messaging().onMessage(remoteMessage => {
            console.log('FCM. onMessage',remoteMessage);
            //firebaseNavigate(remoteMessage, 'onMessage');
        }); */
    }, []);

    const requestLocationPermissions = async () => {
        try {
            await Geolocation.stopObserving();
            const granted = await PermissionsAndroid.request(
                PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
                {
                    title: "Разрешите приложению определять ваше местоположение",
                    message:
                        "Нам необходимо определять ваше местоположения " +
                        "чтобы добавлять его в создаваемые объявления и извещать вас о чрезвычайных событиях",
                    buttonNeutral: "Спросить позже (приложение не будет работать)",
                    buttonNegative: "Запретить (приложение не будет работать)",
                    buttonPositive: "Разрешить"
                }
            );
            if (granted === PermissionsAndroid.RESULTS.GRANTED) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            console.warn(err);
            return false;
        }
    };

    useEffect(() => {
        if(isLoggedIn && currLoc.isPosition == true && currLoc.timestamp - location.timestamp > 3600000) {
            dispatch(setUserLocation(currLoc));
            addGeo(currLoc.latitude,currLoc.longitude).then();
        }
    }, [currLoc]);

    useEffect(() => {
        if(localState.locationPermission) {

            Geolocation.watchPosition(
                (position) => { setCurrLoc({...position.coords, isPosition:true, timestamp: position.timestamp} ) },
                (error) => { console.log(error.code, error.message); },
                { timeout: 15000 }
            );
        }

    }, [localState.locationPermission])

    useEffect(() => {
        if(isLoggedIn) {
            /*
            const firebaseGetToken = messaging().getToken().then(token => {
                //console.log('FCM. TOKEN INIT', token);
                addFirebaseToken(token).then(resp => {
                    if (resp.success) {
                        AsyncStorage.setItem('firebaseDeviceToken', token).then();
                        dispatch(setDeviceToken(token));
                    }
                });
            });

            const firebaseOnTokenRefresh = messaging().onTokenRefresh(token => {
                //console.log('FCM. TOKEN REFRESH', token);
                addFirebaseToken(token).then(resp => {
                    if (resp.success) {
                        AsyncStorage.setItem('firebaseDeviceToken', token).then();
                        dispatch(setDeviceToken(token));
                    }
                });
            }); */
        } else {

        }

    }, [isLoggedIn]);


    const firebaseNavigate = (remoteMessage:any, source:string) => {
        if(remoteMessage?.data?.route == "event") {
            //@ts-ignore
            navigation.navigate('CommonNavigator', { screen: 'EventScreen', params: {eventId: remoteMessage?.data?.object_id } } );
        }
        if(remoteMessage?.data?.route == "message") {
            //@ts-ignore
            navigation.navigate('CommonNavigator', { screen: 'ChatScreen', params: {chatId: remoteMessage?.data?.object_id } } );
        }
    }


    return (

        isLoggedIn ? (
            <>
                {/* @ts-ignore */ }
                <Stack.Navigator  screenOptions={{headerShown: false, presentation: 'card'}}>
                    <Stack.Screen
                        name="BottomTabNavigator"
                        component={BottomTabNavigator}
                    />
                    <Stack.Screen
                        name="CommonNavigator"
                        component={CommonNavigator}
                    />
                </Stack.Navigator>
            </>
        ) : (
            <>
                {/* @ts-ignore */ }
                <Stack.Navigator screenOptions={{headerShown: false, presentation: 'card'}}>
                    <Stack.Screen name="Root" component={AuthNavigator} />
                </Stack.Navigator>
            </>
        )

    );
}
