import React, {FC, useRef, useState} from 'react';
import ViewScreen from "../../../components/Project/ViewScreen/ViewScreen";
import ChatMessages from "../../../components/Project/ChatMessages/ChatMessages";
import BackButton from "../../../components/UI/BackButton/BackButton";
import {translate} from "../../../utils/translate";
import ViewBottom from "../../../components/UI/ViewBottom/ViewBottom";
import InputSend from "../../../components/UI/InputSend/InputSend";
import {Icons} from "../../../constants/images";
import {sendMessage} from "../../../api/chats/sendMessage/sendMessage";
import {View} from "react-native";
import {responseWithBadWords} from "../../../utils/response";
import {Dictionary} from "../../../locales/dictionary";

interface IChatScreen {
    navigation: any;
    route: any;
}

const ChatScreen:FC<IChatScreen> = ({navigation, route, ...props}) => {
    const {chatId = 0 } = route?.params;

    const [message, setMessage] = useState<string>('');
    const [needRefresh, setNeedRefresh] = useState<boolean>(false);

    const inputRef = useRef();
    const onMessageChange = (data:any) => {
        setMessage(data);
    }

    const onMessageSend = ( ) => {
        console.log('TEST',message);
        if(chatId && message != '') {
            const payload = {
                "chat_id": chatId,
                "msg_type": 0,
                "message": message,
            }
            sendMessage(payload).then( (resp) => {
                responseWithBadWords({
                    resp: resp,
                    messageBadWords: Dictionary.errors.badWords,
                    messageError: "errors." + resp.message,
                    callBackSuccess: () => {
                        //@ts-ignore
                        inputRef?.current?.clear();
                        setMessage('');
                        setNeedRefresh(true);
                    }
                })
            })
        }
    }

    return (
        <ViewScreen keyboardVerticalOffset={25}>
            <BackButton
                text={translate('back')}
                action={() => navigation.goBack()}
            />


            <ChatMessages
                chatId={chatId}
                needRefresh = {needRefresh}
                setNeedRefresh = {setNeedRefresh}
            />

            <ViewBottom marginBottom={0}>
                <InputSend
                    inputRef={inputRef}
                    onChange={ onMessageChange }
                    onPress = { onMessageSend }
                    rightIcon={Icons.messageSend}
                    placeHolderText={translate('chat.send')} />
            </ViewBottom>

        </ViewScreen>
    );
};

export default ChatScreen;
