import React, {FC} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import styles from './styles';
import {SvgUri} from 'react-native-svg';
import {CategoryItem} from '../../../types';
import {getSvgUrl} from "../../../utils/common";

type Props = {
    item: CategoryItem;
    onPress: () => void;
    activeItem: number;
};
const FlatListIcons: FC<Props> = ({item, onPress, activeItem,...props}) => {
    let itemStyles:any[] = [styles.itemContainer];
    if(activeItem == item.id) {
        itemStyles.push(styles.itemContainerActive);
    }

    return (
        <TouchableOpacity style={itemStyles} onPress={onPress}>
            <View style={styles.icon}>
                <SvgUri uri={getSvgUrl(item.icon_link)} style={styles.iconContainer} />
            </View>
            <Text style={styles.itemText} numberOfLines={1}>
                {item.name}
            </Text>
        </TouchableOpacity>
    );
};

export default FlatListIcons;
