import {useIsFocused} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {Dimensions, RefreshControl, ScrollView, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {getEvents} from '../../api/events/getEvents';
//import MapComponent from '../../components/MapComponent/MapComponent';
import ButtonSaveEvent from '../../components/Project/ButtonSaveEvent/ButtonSaveEvent';
import CategoriesList from '../../components/Project/CategoriesList/CategoriesList';
import EventsList from '../../components/Project/EventsList/EventsList';
import BackButton from '../../components/UI/BackButton/BackButton';
import SearchInput from '../../components/UI/SearchInput/SearchInput';
import {setEvents} from '../../redux/GlobalRedux/actions/actionCreator';
import {CategoryItem, IEvent} from '../../types';
import {translate} from '../../utils/translate';
import styles from './styles';
import ViewScreen from "../../components/Project/ViewScreen/ViewScreen";
import GetLocation from "react-native-get-location";
//import {IMapCenterPoint} from "../../components/MapComponent/types";
import {getSubCategories} from "../../api/categories/getSubCategories/getSubCutegories";
import SubCategoriesList from "../../components/Project/SubCategoriesList/SubCategoriesList";
import {closeIcon} from "../../constants/images";
import {FORM_TYPE_EMERGENCY} from "../../components/Project/EventForm/data";
import {useDebouncedCallback} from "use-debounce";

const {width, height} = Dimensions.get('window');

const ASPECT_RATIO = width / height;

const Map = (props: any) => {
    const dispatch = useDispatch();
    const focused = useIsFocused()
    const events = useSelector((state: any) => state?.global?.events);
    const [screenEvents, setScreenEvents] = useState<IEvent[]>([])
    const categories = useSelector((state: any) => state?.global?.categories);
    const [showEventList, setShowEventList] = React.useState(false);
    const [showEventCard, setShowEventCard] = React.useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = React.useState<number>(-1);
    const [userMapLocation, setUserMapLocation] = useState<IMapCenterPoint>({
        latitude: 35.05,
        longitude: -118.24,
        latitudeDelta: 0.015,
        longitudeDelta: 0.0121,
    });
    const [getLocationState, setGetLocationState] = useState<boolean>(false);
    const [activeCategory, setActiveCategory] = useState<number>(0);
    const [isMapRefreshing, setIsMapRefreshing] = React.useState(false);

    const [subCategories, setSubCategories] = useState<any[]>([]);
    const [activeSubCategory, setActiveSubCategory] = useState<number>(0);
    const [showSubCategories, setShowSubCategories] = useState<boolean>(false);

    const [screenState, setScreenState] = useState({
        searchString: '',
    })

    const onMapRefresh = () => {
        setIsMapRefreshing(true);
        getEventsList().then(() => {
            setIsMapRefreshing(false);
        });
    }

    const setLocation = async () => {
        const location = await GetLocation.getCurrentPosition({
            enableHighAccuracy: true,
            timeout: 15000,
        });
        setUserMapLocation({
            latitude: location.latitude,
            longitude: location.longitude,
            latitudeDelta: 0.28,
            longitudeDelta: ASPECT_RATIO
        });
        setGetLocationState(true);
    }

    useEffect(() => {
        setLocation().then();
        getEventsList().then();
    }, [focused]);

    useEffect(() => {
        if(activeCategory != 0 ) {
            setShowSubCategories(true);
        } else {
            setShowSubCategories(false);
        }
    }, [activeCategory])

    const onCategoryItemPress = (item: CategoryItem) => {
        getEventsList().then();
        setActiveSubCategory(0);
        if(item.id == activeCategory) {
            setActiveCategory(0);
        } else {
            setActiveCategory(item.id)
        }
    };

    const getEventsList = async () => {
        let payload: {[key: string]: any} = {
            type: 1,
        };
        const evt_ctgy_id = (activeCategory != 0) ? activeSubCategory != 0 ? activeSubCategory:activeCategory:0;
        if(evt_ctgy_id != 0) { payload.evt_ctgy_id = evt_ctgy_id; }
        if(searchString != '') { payload.keyword = searchString; }
        const data = await getEvents(payload);
        return data;
    };

    // when event is pressed
    const onEventPress = (id: number) => {
        navigation.navigate('CommonNavigator', {screen: 'EventScreen', params: {eventId: id}});
    };
    const onShowEventCard = () => {
        setShowEventCard(true);
    };
    const onHideEventCard = () => {
        setShowEventCard(false);
        setSelectedEvent(-1);
    };
    const {navigation} = props;

    useEffect( () => {
        setIsMapRefreshing(true);
        getEventsList().then( resp => {
            dispatch(setEvents(resp))
            setIsMapRefreshing(false);
        })
        //}, [screenState.searchString, activeCategory, activeSubCategory])
    }, [activeCategory, activeSubCategory])


    const [searchString, setSearchString] = useState<string>('');
    const onSearch = async (text:string) => {
        setSearchString(text);
        if(text.length >= 3 || text == '') {
            debounced(text);
        }
    }
    const debounced = useDebouncedCallback(
        (value) => {
            setIsMapRefreshing(true);
            getEventsList().then( resp => {
                dispatch(setEvents(resp))
                setIsMapRefreshing(false);
            })
        },
        10,
        // The maximum time func is allowed to be delayed before it's invoked:
        { maxWait: 15 }
    );
    useEffect(
        () => () => {
            debounced.flush();
        },
        [debounced]
    );

    return (
        <ViewScreen>
            <View style={styles.header}>
                <View style={styles.searchInput}>
                    <SearchInput
                        placeholderText={translate('placeHolderSearchHome')}
                        onSearchPress={ onSearch }
                    />
                </View>
                {/* back button container */}
                {showEventList && (
                    <BackButton
                        text={translate('back')}
                        action={() => {
                            setShowEventList(false);
                        }}
                    />
                )}
                {!showEventList && (
                    <View style={styles.categoriesList}>
                        <CategoriesList
                            onItemPress={onCategoryItemPress}
                            categories={categories}
                            activeCategory={activeCategory}
                        />
                    </View>
                )}
                {showSubCategories && (
                    <SubCategoriesList
                        category_id={activeCategory}
                        subcategory_id={activeSubCategory}
                        setActiveSubCategory={setActiveSubCategory}
                        setShowSubCategories={setShowSubCategories}
                    />
                )}
            </View>
            {getLocationState &&
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={isMapRefreshing}
                        onRefresh={onMapRefresh}
                    />
                }
                contentContainerStyle={StyleSheet.absoluteFillObject}
            >
                /*
                <MapComponent
                    forceCenter={true}
                    onPressEvent={onEventPress}
                    eventMarkers={events}
                    MapCenterPoint={userMapLocation}
                />
                */
            </ScrollView>
            }
            {!showEventList && (
                <View style={styles.bottomButtonContainer}>
                    <ButtonSaveEvent
                        buttonText={translate('event.showList')}
                        onPress={() => {
                            setShowEventList(true);
                        }}
                        style={styles.buttonStyle}
                        textStyle={styles.buttonTextStyle}
                    />
                    {getLocationState &&
                    <ButtonSaveEvent
                        buttonText={translate('event.addEventMap')}
                        onPress={() => {
                            navigation.navigate('CommonNavigator', {screen:'EventFormScreen', params:{
                                'formTypeProp': FORM_TYPE_EMERGENCY,
                                'location': userMapLocation,
                            }})
                        }}
                        style={styles.buttonStyle}
                        textStyle={styles.buttonTextStyle}
                    />
                    }
                </View>
            )}
            {showEventList && (
                <View style={styles.bottomContainer}>
                    {events.length > 0 && (
                        <EventsList
                            data={events}
                            horizontal={true}
                            onEventPress={onEventPress}
                        />
                    )}
                </View>
            )}
        </ViewScreen>
    );
};

export default Map;
