export const defaultImageUri = "https://petapi2.ratsysdev.com/uploads/logo.jpg";
export const defaultSvgUri = "https://petapi2.ratsysdev.com/uploads/pets.svg";
export const defaultImage300 = require('../assets/images/default_img_300.png');

export const beingGoodSimple = require('../assets/images/being_good_is_simple_ru.png');
export const petHelp = require('../assets/images/pet_help_ru.png');
export const headerImage = require('../assets/images/header_image_ru.png');
export const logoPH = require('../assets/images/logo_ph.png');
export const logoFull = require('../assets/images/logo_full.png');
export const defaultEventImg = require('../assets/images/default_event_img.png');
export const markerImage = require('../assets/images/marker.png');

export const tmpDogImg = require('../assets/images/tmp/dog_image.png');
export const tmpKateBigImg = require('../assets/images/tmp/Kate_big.png');
export const tmpCatImg = require('../assets/images/tmp/cat.png');
export const tmpDogBigImg = require('../assets/images/tmp/dog_big.png');
export const tmpKateImg = require('../assets/images/tmp/Kate.png');
export const tmpMichaelImg = require('../assets/images/tmp/Michael.png');

// Icon
export const emailIcon = require('../assets/images/icons/email_icon.png');
export const passwordIcon = require('../assets/images/icons/password_icon.png');
export const phoneIcon = require('../assets/images/icons/phone_icon.png');
export const accountIcon = require('../assets/images/icons/account_icon.png');
export const downIcon = require('../assets/images/icons/down_icon.png');
export const leftIcon = require('../assets/images/icons/left_icon.png');
export const clockIcon = require('../assets/images/icons/clock_icon.png');
export const locationIcon = require('../assets/images/icons/location_icon.png');
export const activeFavouriteIcon = require('../assets/images/icons/active_favourite_icon.png');
export const unActiveFavouriteIcon = require('../assets/images/icons/unactive_favourite_icon.png');
export const addMediaBig = require('../assets/images/icons/addMedia.png');
export const addIcon = require('../assets/images/icons/add_icon.png')
export enum Icons {
    messageSend = require('../assets/images/icons/message__send_icon.png'),
    messageAddMedia = require('../assets/images/icons/message__addMedia_icon.svg'),
    addPng = require('../assets/images/icons/add_icon.png')
}

//TabBarIcon
export const homeIconTab = require('../assets/images/icons/home_tab_icon.png');
export const favIconTab = require('../assets/images/icons/fav_tab_icon.png');
export const mapIconTab = require('../assets/images/icons/map_tab_icon.png');
export const messageIconTab = require('../assets/images/icons/message_tab_icon.png');
export const profileIconTab = require('../assets/images/icons/profile_tab_icon.png');

//SearchInput Icon
export const searchIcon = require('../assets/images/icons/search_icon.png');
export const closeIcon = require('../assets/images/icons/close_icon.png');
