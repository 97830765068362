export const colors = {
  black: '#000000',
  greenPH: '#8AC43A',
  white: '#FFFFFF',
  silver: '#C4C4C4',
  cedar: '#392413',
  halfCedar: '#9c9189',
  rainee: '#BDC7AC',
  greenWhite: '#E8EDE4',
  inActiveTab: '#d1e6b0',
  lightGray: '#F5F5F5',
  greenLight: '#BDC7AC',

  yellow: "orange",
  red: "red",

};
