import React, {FC, useEffect, useState} from 'react';
import {TouchableOpacity} from 'react-native';
import {View, Text, Image} from 'react-native';
import {
    activeFavouriteIcon,
    clockIcon,
    defaultEventImg,
    locationIcon,
    unActiveFavouriteIcon,
} from '../../../../constants/images';
import {Event, IEvent} from '../../../../types';
import {datetimeConvert, getTimeFromDate} from '../../../../utils/datetime';
import styles from './styles';
import {
    getMediaFirst,
    getMediaFull,
    getMediaFullSrc,
    getMediaPreview,
    getMediaPreviewSrc
} from "../../../../utils/common";

interface IEventsListItem {
    data: IEvent;
    onPress: (id: number) => void;
    onAddToFavourite: (id: number) => void;
    onRemoveFromFavourite: (id: number) => void;
};

const EventsListItem: FC<IEventsListItem> = ({
                                                 data,
                                                 ... props}) => {

    const [isFavourite, setIsFavourite] = useState<boolean>(data.isfav ? true:false);
    const handleAddToFavourite = () => {
        setIsFavourite(true);
        props.onAddToFavourite(data.id);
    };
    const handleRemoveFavourite = () => {
        setIsFavourite(false);
        props.onRemoveFromFavourite(data.id);
    };
    const onFavouriteEventPress = () => {
        if (isFavourite) {
            handleRemoveFavourite();
        } else {
            handleAddToFavourite();
        }
    };
    return (
        <TouchableOpacity
            style={styles.container}
            onPress={() => props.onPress(data.id)}>
            <Image
                source={getMediaPreviewSrc(getMediaFirst(data.media))}
                style={styles.eventImg}
                resizeMode={"cover"}
            />
            <View style={styles.contentEvent}>
                <View style={[styles.row, styles.topicTextContainer, {alignItems:"center"}]}>
                    <Text style={styles.topicText} numberOfLines={1}>
                        {data.evt_topic}
                    </Text>
                    <TouchableOpacity onPress={onFavouriteEventPress}>
                        <Text style={styles.topicText} numberOfLines={1}>

                        </Text>
                        <Image
                            source={isFavourite ? activeFavouriteIcon : unActiveFavouriteIcon}
                            style={styles.favouriteIcon}
                        />
                    </TouchableOpacity>
                </View>
                <View style={[styles.row, styles.addressContainer]}>
                    <Image source={clockIcon} style={styles.iconLeft} />
                    <Text style={styles.detailText} numberOfLines={1}>
                        {datetimeConvert(data.created_at) +
                        ', ' +
                        getTimeFromDate(data.created_at)}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Image source={locationIcon} style={styles.iconLeft} />
                    <Text style={styles.detailText} numberOfLines={1}>
                        {data.evt_address}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default EventsListItem;
