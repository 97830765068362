import React, {FC} from 'react';
import ViewScreen from "../../../components/Project/ViewScreen/ViewScreen";
import {useDispatch, useSelector} from "react-redux";
import Profile from "../../../components/Project/Profile/Profile";
import BackButton from "../../../components/UI/BackButton/BackButton";
import {translate} from "../../../utils/translate";
import {ScrollView, View} from "react-native";
import ViewBottom from "../../../components/UI/ViewBottom/ViewBottom";
import ButtonSaveEvent from "../../../components/Project/ButtonSaveEvent/ButtonSaveEvent";
import {setIsLoggedIn} from "../../../redux/AuthRedux/actions/actionCreator";
import {globalStyles, SCREEN_HEIGHT, SCREEN_WIDTH} from "../../../constants/globalStyles";

export interface IProfileUserScreen {
    navigation: any;
    route:any;
}

const ProfileUserScreen:FC<IProfileUserScreen> = ({navigation, route, ...props}) => {

    const userInfo = useSelector((state: any) => state?.session?.userData);
    const profileUserId = route.params?.userId ?? false;

    const dispatch = useDispatch();
    const onLogout = () => {
        dispatch(setIsLoggedIn(false));
    }

    /*
    useEffect(() => {
        getUserById({user_id: userInfo.user_id}).then( (dataUser) => {
            console.log('TEST', dataUser);
        });
    }, []);

     */


    return (
        <ViewScreen>
            {profileUserId &&
            <View style={{position: 'absolute', left: 0}}>
                <BackButton
                    text={translate('back')}
                    action={() => {
                        navigation.goBack()
                    }}
                />
            </View>
            }

            <ScrollView style={{marginTop: (profileUserId != userInfo?.user_id ) ? 25:0}}>
                <Profile
                    user_id={route.params?.userId}
                    navigation = {navigation}
                />
            </ScrollView>

            <ViewBottom marginBottom={0}>
                <View style={{width:SCREEN_WIDTH, padding: 5, alignItems:"center", justifyContent:"center"}}>
                    {profileUserId && profileUserId != userInfo?.user_id &&
                    <View style={{margin: 5}}>
                        <ButtonSaveEvent
                            buttonText={translate("chat.send")}
                            onPress={() => {} }
                        />
                    </View>
                    }
                    { (profileUserId === false || profileUserId === userInfo?.user_id) &&

                    <View style={{margin: 5}}>
                        <ButtonSaveEvent
                            buttonText={translate("auth.logout")}
                            onPress={onLogout}
                        />
                    </View>
                    }
                </View>
            </ViewBottom>
        </ViewScreen>
    );
};

export default ProfileUserScreen;
