import React, {FC, useState} from 'react';
import {Image, KeyboardAvoidingView, Platform, ScrollView, Text, View,} from 'react-native';
import {globalStyles, SCREEN_WIDTH} from '../../../constants/globalStyles';
import Button from '../../../components/UI/Button/Button';
import SubButton from '../../../components/UI/SubButton/SubButton';
import Input from '../../../components/UI/Input/Input';
import {accountIcon, emailIcon, headerImage, passwordIcon, phoneIcon,} from '../../../constants/images';
import styles from './styles';
import {StackNavigationProp} from '@react-navigation/stack';
import {AuthStackParamList} from '../../../types';
import {useNavigation} from '@react-navigation/native';
import {useDispatch} from 'react-redux';
import {translate} from '../../../utils/translate';
import {setIsLoggedIn, updateUserData} from '../../../redux/AuthRedux/actions/actionCreator';
import {signup} from "../../../api/user/registration";
import {getFormData} from "../../../utils/formData";
import {IRegistrationFormData} from "./types";
import {defaultRegData} from "./prepare";
import RegistrationRoles from "./RegistrationRoles/RegistrationRoles";
import RegistrationUR from "./RegistrationUR/RegistrationUR";
import {showMessage} from "react-native-flash-message";
import ButtonSaveEvent from "../../../components/Project/ButtonSaveEvent/ButtonSaveEvent";
import {login} from "../../../api/user/login";
import AsyncStorage from "@react-native-async-storage/async-storage";

type Props = {
  updateRegisterField: (field: string, value: any) => void;
};

const Registration:FC<Props> = (props) => {
  const navigation = useNavigation<StackNavigationProp<AuthStackParamList>>();
  const [errMesg, setErrMsg] = useState('');
  const [isShowErrMsg, setShowErrMsg] = useState(false);
  const [formStep, setFormStep] = useState(1);

  const [formData, setFormData] = useState<IRegistrationFormData>(defaultRegData);

  const dispatch = useDispatch();

  const onFieldChange = ( {form = 'registration', field, data}:{form?:string | undefined, field:string, data: any}) => {
    let tmpFormData = {...formData};
    switch (field) {
      case "name": {
        tmpFormData.name = data;
        const dataArr = data.split(' ');
        tmpFormData.first_name = dataArr[0];
        if(dataArr[1] != undefined) {
          tmpFormData.last_name = dataArr[1];
        } else {
          tmpFormData.last_name = defaultRegData.last_name;
        }
        break;
      }
      case 'role': {
        tmpFormData.role = data;
        if(data == 2 || data == 3) {
          setFormStep(3);
        } else {
          setFormStep(2);
        }
        break;
      }
      default: {
        // @ts-ignore
        tmpFormData[field] = data;
        break;
      }
    }
    setFormData(tmpFormData);
  }

  const onChangeStep = (current: number, next: number) => {
    if(current == 1 && next ==2 ) {

      if (
          !formData.name ||
          !formData.password ||
          !formData.phone ||
          !formData.email ||
          !formData.password_confirmation
      ) {
        showMessage({
          message: translate("registration.errorCommonForm"),
          type: "danger"
        })
        return;
      }
      else if (formData.password_confirmation != formData.password) {
        showMessage({
          message: translate("registration.errorPasswordConfirmation"),
          type: "danger"
        })
        return;
      }
      else if(formData.name.split(' ').length < 2) {
        showMessage({
          message: translate("registration.errorLastName"),
          type: "danger"
        })
        return;
      }
      else if(formData.password.length <8) {
        showMessage({
          message: translate("registration.errorPasswordLength"),
          type: "danger"
        })
        return;
      }
      else {
        setFormStep(next);
      }
    }
    else {
      setFormStep(next);
    }
  }

  const onRegister = async () => {
    try {
      const payload = {...formData};
      const response = await signup(getFormData(payload));
      if (response.data.status === 'success') {
        showMessage({
          message: translate("registration.success"),
          type:"success"
        })
        const loginSuccess = await onLogin(payload.email, payload.password);
        if(!loginSuccess) {
          navigation.navigate('Login');
        }
      } else {
        showMessage({
          message: response?.data?.message?? translate("registration.errorUnknown"),
          type:"danger"
        });
      }
    } catch (error: any) {
      showMessage({
        message:error?.response?.data?? translate("registration.errorUnknown"),
        type:"danger"
      });
    }
  }

  const onLogin = async (email:string, password:string) => {
    const payload = {
      email,
      password,
    };
    let output = false;
    try {
      const response = await login(getFormData(payload));
      console.log('LOGIN 1', response);
      if (response.data.status === 'success') {
        console.log('LOGIN 2', response.data);
        await AsyncStorage.setItem('apiToken', response?.data?.token);
        dispatch(updateUserData({...response?.data}));
        dispatch(setIsLoggedIn(true));
        output = true;
      } else {
        console.log('LOGIN 3', response.data);
        showMessage({
          message: translate("errors." + response?.data?.message?? "unknownError" ),
          type: "danger"
        });

        output = false;
      }

    } catch (error: any) {
      console.log('LOGIN 4', error);
      showMessage({
        message: translate("errors." + error?.response?.data?.message?? "unknownError" ),
        type: "danger"
      });

      output = false;
    }

    return output;
  }

  return (
      <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          keyboardVerticalOffset={20}
          style={{flex: 1}}
          contentContainerStyle={globalStyles.flexOne}>
        <ScrollView contentContainerStyle={styles.container}>
          {formStep == 1 &&
          <View style={styles.container}>
            <View style={styles.headerContainer}>
              <View style={globalStyles.headerContainer}>
                <Image source={headerImage} style={globalStyles.headerImage}/>
              </View>
            </View>
            <View style={styles.bodyContainer}>
              <View>
                <Input
                    valueText={formData.name}
                    onChange={(value: any) => onFieldChange({field: "name", data: value})}
                    placeHolderText={translate("registration.name")}
                    icon={accountIcon}
                    isShowIcon
                />
              </View>
              <View>
                <Input
                    valueText={formData.phone}
                    //onChange={onChangePhoneNumber}
                    onChange={(value: any) => onFieldChange({field: "phone", data: value})}
                    placeHolderText={translate("registration.phone")}
                    icon={phoneIcon}
                    type={'telephoneNumber'}
                    isPassword={false}
                    isShowIcon
                />
              </View>

              <View>
                <Input
                    valueText={formData.email}
                    //onChange={onChangeEmail}
                    onChange={(value: any) => onFieldChange({field: "email", data: value})}
                    placeHolderText={translate("registration.email")}
                    type="emailAddress"
                    icon={emailIcon}
                    isShowIcon
                />
              </View>
              <View>
                <Input
                    valueText={formData.password}
                    //onChange={onChangePassword}
                    onChange={(value: any) => onFieldChange({field: "password", data: value})}
                    placeHolderText={translate("registration.password")}
                    type="password"
                    icon={passwordIcon}
                    isPassword
                    isShowIcon
                />
              </View>
              <View>
                <Input
                    valueText={formData.password_confirmation}
                    //onChange={onChangePasswordConfirmation}
                    onChange={(value: any) => onFieldChange({field: "password_confirmation", data: value})}
                    placeHolderText={translate("registration.passwordConfirmation")}
                    type="password"
                    icon={passwordIcon}
                    isPassword
                    isShowIcon
                />
              </View>
            </View>
            <View style={styles.bottomContainer}>
              {isShowErrMsg && (
                  <View style={styles.errorMessage}>
                    <Text style={styles.errorMessageText}>{errMesg}</Text>
                  </View>
              )}
              <View style={styles.descriptionMsg}>
                <Text style={styles.descriptionMsgText}>
                  {translate("registration.userAgreement1") } {' '}
                  <Text style={styles.textLink}>{translate("registration.userAgreement2") }</Text>{' '}
                  {translate("registration.userAgreement3") } {' '}
                  <Text style={styles.textLink}>{translate("registration.userAgreement2") }</Text>.
                </Text>
              </View>
              <Button text={translate("auth.registration")} loading={false} action={ () => onChangeStep(1,2)} />
              <SubButton text={translate("auth.back")}  action={ () => {navigation.goBack();}} />
              {/*
            <Button text={translate("auth.registration")} loading={false} action={validateForm} />
            <SubButton text={translate("auth.back")}  action={goBack} />
            */}
            </View>
          </View>
          }
          {formStep == 2 &&
          <View style={styles.container}>
            <View style={styles.headerContainer}>
              <View style={globalStyles.headerContainer}>
                <Image source={headerImage} style={globalStyles.headerImage}/>
              </View>
            </View>
            <View style={styles.bodyContainer}>
              <RegistrationRoles />
              <ButtonSaveEvent style={{marginTop:10, width:SCREEN_WIDTH*0.9}} buttonText={translate("registration.urip")} onPress={ () => onFieldChange({field:"role", data:3})} />
              <ButtonSaveEvent style={{marginTop:10, width:SCREEN_WIDTH*0.9}} buttonText={translate("registration.urur")} onPress={ () => onFieldChange({field:"role", data:2})} />
              <SubButton styleContainer={{marginTop:10}} text={translate("registration.backToReg")} action={ () => onChangeStep(2,1)} />
              <SubButton styleContainer={{marginTop:10}} text={translate("registration.urno")} action={ onRegister }/>
            </View>
          </View>
          }
          {formStep == 3 &&
          <View style={styles.container}>
            <View style={styles.headerContainer}>
              <View style={globalStyles.headerContainer}>
                <Image source={headerImage} style={globalStyles.headerImage}/>
              </View>
            </View>
            <View style={styles.bodyContainer}>
              <RegistrationUR formData={formData} onFieldChange={onFieldChange}/>
              <ButtonSaveEvent style={{marginTop:10, width:SCREEN_WIDTH*0.9}} buttonText={translate("auth.registration")} onPress={ onRegister } />
              <SubButton
                  styleContainer={{marginTop:10}}
                  text={translate("registration.back")}
                  action={ () => { onFieldChange({field:"role",data:1})} }/>
            </View>
          </View>
          }
        </ScrollView>
      </KeyboardAvoidingView>
  );
};

export default Registration;
