import React, {FC, useEffect, useRef, useState} from 'react';
import {ActivityIndicator, Image, ScrollView, Text, View,} from 'react-native';
import {getEventById} from '../../../api/events/getEventById';
import {clockIcon, locationIcon} from '../../../constants/images';
import {Event} from '../../../types';
import {datetimeConvert, getTimeFromDate} from '../../../utils/datetime';
import {getFormData} from '../../../utils/formData';
import styles from './styles';
import CommentsList from "../CommentList/CommentsList";
import FlatListImages from "../../Base/FlatListImages/FlatListImages";
import {SvgUri, SvgXml} from "react-native-svg";
import {getSvgUrl, getSvgXml} from "../../../utils/common";
import ProfileRating from "../Profile/ProfileRating/ProfileRating";
import {SCREENS} from "../../../navigation/screenName";
import {useNavigation} from "@react-navigation/native";
import {translate} from "../../../utils/translate";
import {colors} from "../../../constants/Colors";
import Button from "../../UI/Button/Button";
import {addChat} from "../../../api/chats/addChat/addChat";
import {useSelector} from "react-redux";
import {showMessage} from "react-native-flash-message";
import FlatListMedia from "../../Base/FlatListMedia/FlatListMedia";
import { getEvents } from '../../../api/events/getEvents/getEvents';


type Props = {
  eventId: number;
  needCommentsRefresh:boolean;
  setNeedCommentsRefresh: any;
};

interface ICompState {
  isChatLoading: boolean,
  event: Event | null,
  isEventLoading: boolean,

}
const EventCard: FC<Props> = ({eventId, needCommentsRefresh, setNeedCommentsRefresh, ...props}) => {



  const [loading, setLoading] = useState<boolean>(true);
  const [compState, setCompState] = useState<ICompState>({
    isChatLoading: false,
    event: null,
    isEventLoading: true,
  })
  const navigation = useNavigation();
  const userInfo = useSelector((state: any) => state?.session?.userData);

  const eventTypes = {
    event: 1,
    post: 2
  };

  const inputRef = useRef();
  const onAddChat = async () => {
    setCompState({...compState, isChatLoading: true});
    if(compState.event) {
      const response = await addChat({
        chat_user: compState.event.user_id,
        event_id: compState.event.id,
        chat_initiator: userInfo.user_id
      })
      if (response.success) {
        /*
        showMessage({
          message: response.message,
          type: "success"
        });
         */
        // @ts-ignore
        navigation.navigate('CommonNavigator', {screen: 'ChatScreen', params: {chatId: response.data}});
      } else {
        showMessage({
          message: response.message,
          type: "danger"
        });
      }
    }
    else {
      showMessage({
        type: "warning",
        message: translate("errors.unknown")
      });
      setCompState({...compState, isChatLoading: false});
    }


  }

  useEffect(() => {
    getEventDetail().then();
  }, []);
  const getEventDetail = async () => {
    const payload = {
      evt_id: eventId,
    };
    try {
      const response = await getEvents(getFormData(payload));
      /*
      const response = await getEvents(payload);
      console.log(response);
      return;

       */

        let eventTmp =  response[0];

        if(eventTmp.media.length == 0) {
          eventTmp.media.push('');
        }

        eventTmp.categoryData = (compState.event?.category?.sub_category)
            ? {
              category: eventTmp.category?.sub_category,
              category_icon_link: eventTmp.category?.sub_category_icon_link,
              category_id: eventTmp.category?.sub_category_id,
            }
            : {
              category: eventTmp.category?.main_category,
              category_icon_link: eventTmp.category?.main_category_icon_link,
              category_id: eventTmp.category?.main_category_id,
            }
        ;

        eventTmp.categoryIconXml = await getSvgXml(eventTmp.categoryData.category_icon_link);
        if(eventTmp.is_emergency) {
          /*eventTmp.priorityColor = priorityToColor[eventTmp.evt_priority];
          let tmpIconXml = await getSvgXml(compState.event?.category?.main_category_icon_link);
          eventTmp.categoryIconXml = tmpIconXml.toString()
              .replace(/fill="#[0-9a-f]{6}"/g, `fill="${eventTmp.priorityColor}"`)
              .replace(/stroke="#[0-9a-f]{6}"/g, `stroke="${eventTmp.priorityColor}"`)

           */
        }
        setCompState({...compState, event:eventTmp, isEventLoading: false })

    } catch (error) {}
  };

  const navigateToUser = (id:any) => {
    if(userInfo.user_id == id) {
      // @ts-ignore
      navigation.navigate('BottomTabNavigator', {screen: SCREENS.Profile});
    } else {
      // @ts-ignore
      navigation.navigate('CommonNavigator', {screen: SCREENS.ProfileUserScreen, params: {userId: id}})
    }
  }

  return (
      <View style={styles.container}>
        {compState.isEventLoading ? (
            <View style={styles.loading}>
              <ActivityIndicator size={'large'} />
            </View>
        ) : (
            <View style={{flex: 1}}>
              <ScrollView>

                {/*<View style={[globalStyles.row, styles.imagesContainer]}>*/}

                <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                  {compState.event && compState.event?.media &&
                  <FlatListMedia items={compState.event.media} activeItem={0} onItemPress={() => {}} />
                  }
                </View>

                <View style={{flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
                  <Text style={styles.topicText}>{compState.event?.evt_topic}</Text>
                  {compState.event?.is_emergency && compState.event?.user_id == userInfo.user_id
                      ?
                      <SvgXml
                          xml={compState.event?.categoryIconXml.toString()}
                          width={48}
                          height={48}
                      />
                      :
                      <></>
                  }
                </View>
                {compState.event && compState.event.type != eventTypes.post &&
                <View style={[styles.detailRow, {marginBottom: 10, marginTop: 0}]}>
                  <SvgUri uri={getSvgUrl(compState.event?.categoryData.category_icon_link)} style={styles.icon}/>
                  <Text style={styles.detailText}>
                    {compState.event?.categoryData.category}
                  </Text>
                </View>
                }
                <View style={styles.detailRow}>
                  <Image source={clockIcon} style={styles.icon} />
                  <Text style={styles.detailText}>
                    {datetimeConvert(compState.event?.created_at!) +
                    ', ' +
                    getTimeFromDate(compState.event?.created_at!)}
                  </Text>
                </View>
                {compState.event && compState.event.type != eventTypes.post &&
                <View style={styles.detailRow}>
                  <Image source={locationIcon} style={styles.icon}/>
                  <Text style={styles.detailText}>{compState.event?.evt_address}</Text>
                </View>
                }
                <View style={styles.detailRow}>
                  <Text style={styles.detailText}>{compState.event?.description}</Text>
                </View>
                {compState.event?.user_id &&
                <View style={[styles.detailRow]}>
                  <ProfileRating
                      profileId={compState.event.user_id}
                      showAvatar
                      avatarPosition="right"
                      showName
                      namePosition="top"
                      showCountText={false}

                      onPress={  navigateToUser }
                  />
                </View>
                }
                <View style={{marginTop:10, width:"100%", alignItems:"center"}}>
                  <Button text={"Отправить сообщение"}
                          action={ onAddChat }
                          styleContainer={{backgroundColor: colors.white, borderWidth:1, borderColor: colors.greenPH}}
                          styleText={{color:colors.greenPH}}
                  />
                </View>



                <CommentsList
                    eventId={eventId}
                    eventDetail={compState.event}
                    needCommentsRefresh={needCommentsRefresh}
                    setNeedCommentsRefresh={setNeedCommentsRefresh}
                    navigateToUser = { navigateToUser }
                />
              </ScrollView>
            </View>
        )}
      </View>
  );
};

export default EventCard;
