import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Image, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {globalStyles} from '../../constants/globalStyles';
import CategoriesList from '../../components/Project/CategoriesList/CategoriesList';
import EventsList from '../../components/Project/EventsList/EventsList';
import styles from './styles';
import {translate} from '../../utils/translate';

import {CategoryItem, IEvent} from '../../types';
import SearchInput from '../../components/UI/SearchInput/SearchInput';
//import getCategories from '../../api/categories/getCategories';
import {useDispatch, useSelector} from 'react-redux';
import {setCategories, setUserPets,} from '../../redux/GlobalRedux/actions/actionCreator';
import {getUserPets} from '../../api/pets';
import {useIsFocused} from '@react-navigation/native';
import ViewScreen from "../../components/Project/ViewScreen/ViewScreen";
import SubCategoriesList from "../../components/Project/SubCategoriesList/SubCategoriesList";
import {capitalizeFirstLetter} from "../../utils/text";
import ButtonAdd from "../../components/Project/ButtonAdd/ButtonAdd";
import {Dictionary} from "../../locales/dictionary";
import {colors} from "../../constants/Colors";
import { getEvents } from '../../api/events/getEvents/getEvents';
import {closeIcon} from "../../constants/images";
import {fonts} from "../../constants/fonts";
import {useDebouncedCallback} from "use-debounce";
import {getCategories} from "../../api/categories/getCategories";

import '../../../index.css';


const FEED_TYPE_ALL = 0;
const FEED_TYPE_EVENTS = 1;
const FEED_TYPE_POSTS = 2;

interface IScreenState {
  //searchString: string,
  feedMode: 'events' | 'posts' | 'feed',
  feedType: 0 | 1 | 2,
  isEventsLoading: boolean,
  swipeStart: any,
  swipeEnd: any,
  swipeSens: number
}

const Home = (props: any) => {
  const dispatch = useDispatch();
  const categories = useSelector((state: any) => state?.global?.categories);
  const userInfo = useSelector((state: any) => state?.session?.userData);
  //const events = useSelector((state: any) => state?.global?.events);
  const [screenEvents, setScreenEvents] = useState<IEvent[]>([]);
  const posts = useSelector((state: any) => state?.global?.posts);
  const focused = useIsFocused();
  const {navigation} = props;
  const [selectedEvent, setSelectedEvent] = useState<number>(-1);
  const [categoryId, setCategoryId] = useState<number>(0);


  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [activeSubCategory, setActiveSubCategory] = useState<number>(0);
  const [showSubCategories, setShowSubCategories] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>('');

  const [screenState, setScreenState] = useState<IScreenState>({
    //searchString: '',
    feedMode: 'events',
    feedType: 1,
    isEventsLoading: true,
    swipeStart: 0,
    swipeEnd: 0,
    swipeSens: 40,
  })

  const onSwipeLeft = () => {
    setScreenState({...screenState, feedType: FEED_TYPE_POSTS})
  }

  const onSwipeRight = () => {
    setScreenState({...screenState, feedType: FEED_TYPE_EVENTS})
  }

  //const { onTouchStart, onTouchEnd } = useSwipe(onSwipeLeft, onSwipeRight, 10)

  useEffect(() => {
    console.log('HOME FOCUSED', focused);
    if(focused) {
      getCategoriesList().then();
      setScreenState({...screenState, isEventsLoading: true})
      getEventsList().then(resp => {
        setScreenEvents(resp);
        setScreenState({...screenState, isEventsLoading: false})
        //dispatch(setEvents(resp))
      });
    }

    /*
    if (focused) {
      getCategoriesList().then();
      getEventsList().then( resp => dispatch(setEvents(resp)) );
      //getUserPetList();
    } else {
      getEventsList().then(  resp => dispatch(setEvents(resp)) );
    }
    */
  }, [focused]);

  const getCategoriesList = async () => {
    const data = []; //await getCategories();
    dispatch(setCategories(data));
  };

  useEffect(() => {
    if(activeCategory != 0 ) {
      setShowSubCategories(true);
    } else {
      setShowSubCategories(false);
    }
  }, [activeCategory])

  const getUserPetList = async () => {
    const payload = {
      user_id: userInfo?.user_id,
    };
    const token = userInfo?.token;
    const data = await getUserPets(payload, token);
    dispatch(setUserPets(data));
  };

  const getEventsList = async (type = undefined) => {
    console.log('SEARCH API CALL 2', screenState);
    let payload: {[key: string]: any} = {};
    const evt_ctgy_id = (activeCategory != 0) ? activeSubCategory != 0 ? activeSubCategory:activeCategory:0;
    if(evt_ctgy_id != 0) { payload.evt_ctgy_id = evt_ctgy_id; }
    if(searchString != '') { payload.keyword = searchString; }
    payload.type = screenState.feedType;
    console.log('SEARCH API CALL 2.1', payload);
    const data = await getEvents(payload);
    console.log('SEARCH API CALL 2.2', data);
    return data;
  };

  const onCategoryItemPress = (item: CategoryItem) => {
    setActiveSubCategory(0);
    setCategoryId(item.id);
    if(item.id == activeCategory) {
      setActiveCategory(0);
    } else {
      setActiveCategory(item.id);
    }
  };
  const onEventPress = (id: number) => {
    setSelectedEvent(id);
    navigation.navigate('CommonNavigator', { screen: 'EventScreen', params: {eventId: id } } );
  };

  const onNewEventPress = () => {
    navigation.navigate('CommonNavigator', { screen: 'EventFormScreen', params: {category_id: categoryId} } );
  }

  /*
  const onSearchClear = async () => {
    setSearchString('')
  }

  const onSearch = async (data:string) => {
    setSearchString(data);
  }
  */

  useEffect( () => {
    setScreenState({...screenState, isEventsLoading: true})
    getEventsList().then( resp => {
      setScreenEvents(resp);
      setScreenState({...screenState, isEventsLoading: false})
      //dispatch(setEvents(resp))
    } );
  }, [screenState.feedType, activeCategory, activeSubCategory])


  const onSearch = async (text:string) => {
    setSearchString(text);
    if(text.length >= 3 || text == '') {
      debounced(text);
    }
  }
  const debounced = useDebouncedCallback(
      (value) => {
        setScreenState({...screenState, isEventsLoading: true})
        getEventsList().then( resp => {
          setScreenEvents(resp);
          setScreenState({...screenState, isEventsLoading: false})
        } );
      },
      10,
      // The maximum time func is allowed to be delayed before it's invoked:
      { maxWait: 15 }
  );
  useEffect(
      () => () => {
        debounced.flush();
      },
      [debounced]
  );


  return (
      <ViewScreen keyboardVerticalOffset={0}>
        <View style={styles.searchInput}>
          {/*
          <View style={{    height: 48,
            backgroundColor: colors.lightGray,
            borderRadius: 41,
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: 16,
            paddingRight: 19,}}>

            <TextInput
                value={inputValue}
                style={{    height: 48,
                  backgroundColor: colors.lightGray,
                  marginHorizontal: 16,
                  flex: 1,
                  fontSize: 14,
                  fontFamily: fonts.Bold,
                  lineHeight: 17}}
                placeholder={"Поиск"}
                placeholderTextColor={colors.black}
                onChangeText={ (text) => { onChangeText(text) }  }
            />
            <TouchableOpacity onPress={ () => { onClearText('') } }>
              <Image source={closeIcon} style={{    height: 11, width: 11}} />
            </TouchableOpacity>
          </View>
          */}

          <SearchInput
              placeholderText={translate('placeHolderSearchHome')}
              onSearchPress={ onSearch }
          />

        </View>
        <View style={styles.categoriesList}>
          <CategoriesList
              onItemPress={onCategoryItemPress}
              categories={categories}
              activeCategory={activeCategory}
          />
          {showSubCategories && (
              <SubCategoriesList
                  category_id={activeCategory}
                  subcategory_id={activeSubCategory}
                  setActiveSubCategory={setActiveSubCategory}
                  setShowSubCategories={setShowSubCategories}
              />
          )}
        </View>
        <View style={[globalStyles.row, styles.listTitle]} >
          <ButtonAdd buttonSize={32} onPress={ onNewEventPress } />

          <View style={{flexDirection:"row", alignItems: "center"}}>

            <TouchableOpacity onPress={ () => {onSwipeRight()} }>
              <Text
                  style={[
                    globalStyles.bodySectionTitle,
                    {marginLeft:5, borderBottomColor: colors.greenPH},
                    screenState.feedType == FEED_TYPE_EVENTS  && {borderBottomWidth:1}]
                  }>
                {capitalizeFirstLetter(translate(Dictionary.event.feed))}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={ () => {onSwipeLeft()} }>
              <Text style={[globalStyles.bodySectionTitle, {marginLeft:10, borderBottomColor: colors.greenPH}, screenState.feedType == FEED_TYPE_POSTS  && {borderBottomWidth:1}  ]}>
                {capitalizeFirstLetter(translate(Dictionary.post.posts))}
              </Text>
            </TouchableOpacity>
            {/*
            <Picker
                selectedValue={screenState.swipeSens}
                style={{ height: 10, width:150 }}
                onValueChange={(itemValue, itemIndex) => setScreenState({...screenState, swipeSens:itemValue})}
            >
              {[20,40,60,80,100,120,140,160,180,200].map( sens =>
                  <Picker.Item key={sens} label={sens.toString()} value={sens} />
              )}
            </Picker>
            */}
          </View>
        </View>

        <View style={[styles.eventList, { justifyContent: "center"}]}
            //onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}

        >
          <>
            {screenState.isEventsLoading !== false && (
                <ActivityIndicator size={48}/>
            )}
            {screenState.isEventsLoading === false && screenEvents.length > 0 && (
                <EventsList
                    data={screenEvents}
                    onEventPress={onEventPress}
                    onTouchStart={ (e) =>  {
                      const posX = e.nativeEvent.pageX;
                      setScreenState({...screenState, swipeStart: posX});
                    } }
                    onTouchEnd={ (e) => {
                      const posX = e.nativeEvent.pageX;
                      console.log('screenState.swipeSens', screenState.swipeSens);
                      console.log('swipe diff', screenState.swipeStart - posX );

                      if (screenState.swipeStart - posX > screenState.swipeSens) {
                        onSwipeLeft();
                        return;
                      }
                      if (posX - screenState.swipeStart > screenState.swipeSens) {
                        onSwipeRight();
                        return;
                      }
                    }}
                />
            )}
          </>
        </View>

      </ViewScreen>
  );
};

export default Home;
