import {showMessage} from "react-native-flash-message";
import {translate} from "./translate";
import {Dictionary} from "../locales/dictionary";

interface IResponseWithBadWords {
    resp: any,
    messageSuccess?: string,
    messageBadWords: string,
    messageError: string,
    callBackSuccess?: () => void,
    callBackBadWords?: () => void,
    callBackError?: () => void,

}

export const responseWithBadWords = ({
                                         resp,
                                         messageSuccess,
                                         messageBadWords,
                                         messageError,
                                         callBackSuccess,
                                         callBackBadWords,
                                         callBackError,

}:IResponseWithBadWords) => {
    if(resp.success) {
        if(messageSuccess) {
            showMessage({
                message: translate(messageSuccess),
                type: "success"
            })
        }
        //@ts-ignore
        if(callBackSuccess) {
            callBackSuccess();
        }
        return;
    } else if (resp.originResp.data.bad_words) {
        showMessage({
            message: translate(messageBadWords) + ': ' + resp.originResp.data.bad_words.join(', '),
            type: "danger"
        })
        if(callBackBadWords) {
            callBackBadWords();
        }
        return;
    } else {
        showMessage({
            message: translate(messageError),
            type: "danger"
        });
        if(callBackError) {
            callBackError();
        }
        return;
    }
}
