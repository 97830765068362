import React, {FC} from 'react';
import {FlatList, View} from 'react-native';
import {removeEventFromFavorite} from '../../../api/user/removeFavEvent';
import {saveEventToFavorite} from '../../../api/user/saveFavEvent';
import {globalStyles} from '../../../constants/globalStyles';
import {IEvent} from '../../../types';
import {getFormData} from '../../../utils/formData';
import styles from './styles';
import {useSelector} from "react-redux";
import EventsListItem from "./EventsListItem/EventsListItem";
import {showMessage} from "react-native-flash-message";
import {translate} from "../../../utils/translate";

type IEventListProps = {
  data: any[];
  onEventPress?: (id: number) => void;
  horizontal?: boolean;
  onRemoveFromFav?: () => void;
  onTouchStart?: (e:any) => void;
  onTouchEnd?: (e:any) => void;
};

const EventsList: FC<IEventListProps> = ({
                                           onEventPress= () => {},
                                           onRemoveFromFav = () => {},
                                           onTouchStart = (e) => {},
                                           onTouchEnd = (e) => {},
                                           ...props
                                         }) => {

  const userInfo = useSelector((state: any) => state?.session?.userData);

  const onAddFavouriteEventPress = async (eventId: number) => {
    try {
      const payload = {
        evt_id: eventId,
      };
      const response = await saveEventToFavorite(userInfo.token, getFormData(payload));
      if (response.data.status === 'success') {
        showMessage({
          message: translate("event.addToFavSuccess"),
          type: "success"
        });
      } else {
        showMessage({
          message: translate("errors.unknownError"),
          type: "danger"
        });
      }
    } catch (error) {
      showMessage({
        message: translate("errors.unknownError"),
        type: "danger"
      });
    }
  };

  const onRemoveFavouriteEventPress = async (eventId: number) => {
    try {
      const payload = {
        evt_id: eventId,
      };
      const response = await removeEventFromFavorite(userInfo.token,getFormData(payload));
      if (response.data.status === 'success') {
        showMessage({
          message: translate("event.removeFromFavSuccess"),
          type: "success"
        });
        if(onRemoveFromFav) { onRemoveFromFav() }
      } else {
        showMessage({
          message: translate("errors.unknownError"),
          type: "danger"
        });
      }
    } catch (error) {
      showMessage({
        message: translate("errors.unknownError"),
        type: "danger"
      });
    }
  };

  const _renderItem = ({item, index}: {item: IEvent; index: number}) => {
    return (
        <EventsListItem
            data={item}
            onPress={onEventPress}
            onAddToFavourite={onAddFavouriteEventPress}
            onRemoveFromFavourite={onRemoveFavouriteEventPress}
        />
    );
  };

  return (
      <View style={globalStyles.flexOne}>
        {props.horizontal === true ? (
            <FlatList
                horizontal
                data={props.data}
                keyExtractor={(item: IEvent) => item.id.toString()}
                contentContainerStyle={styles.listEvent}
                renderItem={_renderItem}
                ItemSeparatorComponent={() => <View style={styles.separator} />}
                showsHorizontalScrollIndicator={false}
            />
        ) : (
            <FlatList
                onTouchStart={ (e) => {
                  //console.log('onTouchStart', e.nativeEvent)
                  onTouchStart(e);
                } }
                onTouchEnd={ (e) => {
                  //console.log('onTouchEnd', e.nativeEvent)
                  onTouchEnd(e);
                } }
                onTouchCancel={ (e) => {
                  //console.log('onTouchCancel', e.nativeEvent)
                  onTouchEnd(e);
                } }

                numColumns={2}
                data={props.data}
                keyExtractor={(item: IEvent) => item.id.toString()}
                columnWrapperStyle={styles.flatlistColumn}
                contentContainerStyle={styles.listEvent}
                renderItem={_renderItem}
            />
        )}
      </View>
  );
};

export default EventsList;
