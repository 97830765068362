import {IMapCenterPoint} from "../../../components/MapComponent/types";
import {translate} from "../../../utils/translate";

export const fieldsDataDefault = {
    evt_topic: '',
    city: '',
    address: '',
    description: '',
    price_from: '',
    price_to: '',
    is_emergency: 0,
    evt_long: '',
    evt_lat:''
}

export const fieldsPetDataDefault = {
    type_id: 0,
    age: 0,
    color: "",
    chip: "",
    description: "",
    images: [],
    address: "",
    contact_details: "Нет контактов",
    sex:0,
    name:'',
    species: '',
    sterilization: 0,
}

export const mapCenterDefault:IMapCenterPoint = {
    latitude: 35.05,
    longitude: -118.24,
    latitudeDelta: 0.015,
    longitudeDelta: 0.0121,
}

export const petsListDefault = [{
    label: translate('event.petListPlaceholder'),
    value: 0
}];