import React, {FC, useEffect, useState} from 'react';
import {Platform, ScrollView, Text, TextInput, View} from "react-native";
import {IPostFormProps} from "./types";
import {styles as profileStyles} from "../Profile/styles";
import {translate} from "../../../utils/translate";
import {useIsFocused} from "@react-navigation/native";
import {colors} from "../../../constants/Colors";
import styles from "./styles";
import {IEventOnFieldChange} from "../EventForm/types";
import MediaAddList from "../../UI/MediaAddList/MediaAddList";
import {showMessage} from "react-native-flash-message";
import {createEvent} from "../../../api/events/createEvent/createEvent";
import {getFormData} from "../../../utils/formData";
import {addEventMediaMultiply} from "../../../api/events/addEventMediaMultiply/addEventMediaMultiply";
import ButtonSaveEvent from "../ButtonSaveEvent/ButtonSaveEvent";
import {capitalizeFirstLetter} from "../../../utils/text";
import {responseWithBadWords} from "../../../utils/response";
import {Dictionary} from "../../../locales/dictionary";

const PostForm:FC<IPostFormProps> = ({post_id, navigation, route, ...props}) => {
    const isFocused = useIsFocused();
    const [isLoaded, setIsLoaded] = useState<any>(false);
    const [medias, setMedias] = useState<any[]>([]);

    const [fieldsData, setFieldsData] = useState({
        evt_topic: '',
        description: '',

    });

    useEffect(() => {
        if(isFocused) {
            setIsLoaded(true);
        }
    }, [isFocused]);

    const onFieldChange = ({form = 'event', field, data}:IEventOnFieldChange) => {
        let tmpFieldsData = {...fieldsData};
        //@ts-ignore
        tmpFieldsData[field] = data;
        setFieldsData(tmpFieldsData);
    }

    const onChangeMedias = (data:any[]) => {
        setMedias(data);
    }

    const onSubmit = async () => {
        //props.onHideModal();
        try {
            // 1. Event Data generate
            const eventData = {
                evt_topic: fieldsData.evt_topic,
                description: fieldsData.description,
                type: 2,

                evt_ctgy_id: 999,
                evt_address: 'a',
                evt_lat: 1,
                evt_long: 1,
                evt_images: [],
                evt_priority: 1,
                is_emergency: 0,
                evt_status: 1,
            };
            const formData = getFormData(eventData);
            const eventResponse = await createEvent(formData);

            responseWithBadWords({
                resp: eventResponse,
                messageBadWords: Dictionary.errors.badWords,
                messageError: "errors." + eventResponse.message,
                callBackSuccess: () => {
                    addEventImages(eventResponse.data).then(() => {
                        showMessage({
                            message: translate(Dictionary.post.addPostSuccess),
                            type: "success"
                        });
                        navigation.goBack();
                    });
                }
            })

        } catch (error: any) {
            showMessage({
                message: translate('errors.unknownError'),
                type: "danger"
            });
        }


    }



    const addEventImages = async (eventId: any) => {
        let imageForm = new FormData();
        let mediaFiles: any[] = [];
        imageForm.append("event_id", eventId);

        medias.map( async (item, index) => {
            imageForm.append(`media_files[${index}]`,{
                name: item.name,
                type: item.type,
                uri: Platform.OS === 'ios' ? item.uri.replace('file://', '') : item.uri,
            });
        })
        const response = await addEventMediaMultiply(imageForm);
        return true;
    }

    return (
        <ScrollView style={{width:"100%"}}>
            {isLoaded &&
            <View style={profileStyles.profile}>
                <View style={profileStyles.profileHeader}>
                    <View style={{width: "100%", justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={{
                            fontSize: 18,
                            color: "#392413",
                            lineHeight: 22.5,
                            fontWeight: "500"
                        }}>{translate('post.addPost')}</Text>
                    </View>

                    <View style={profileStyles.profileSettingsContainer}>
                        <TextInput
                            placeholder={translate('event.topic')}
                            placeholderTextColor={colors.halfCedar}
                            style={[styles.input]}
                            underlineColorAndroid={colors.greenPH}
                            onChangeText={(data) => {
                                onFieldChange({field: 'evt_topic', data: data})
                            }}
                        />
                        <TextInput
                            placeholder={translate('description')}
                            placeholderTextColor={colors.halfCedar}
                            style={styles.bigInput}
                            onChangeText={(data) => {
                                onFieldChange({field: 'description', data: data})
                            }}
                        />
                        <MediaAddList medias={medias} onChangeMedias={onChangeMedias} />
                        <View style={styles.submitButton}>
                            <ButtonSaveEvent
                                onPress={onSubmit}
                                buttonText={ translate('common.add').toUpperCase() }
                            />
                        </View>
                    </View>
                </View>
            </View>
            }
        </ScrollView>
    );
};

export default PostForm;
