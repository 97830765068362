import React, {FC} from 'react';
import {Image, Text, TouchableOpacity, View} from "react-native";
import {capitalizeFirstLetter} from "../../../utils/text";
import {getTranslateMessage, translate} from "../../../utils/translate";

//import {BorderlessButton} from "react-native-gesture-handler";
import DocumentPicker from "react-native-document-picker";
import {addMediaBig} from "../../../constants/images";
import {IMedia} from "../../../types";
import {getMediaPreviewSrc} from "../../../utils/common";
import {globalStyles} from "../../../constants/globalStyles";
import {Dictionary} from "../../../locales/dictionary";
import Button from "../Button/Button";
import ButtonAdd from "../../Project/ButtonAdd/ButtonAdd";
import {showMessage} from "react-native-flash-message";
import {styles} from "./styles";

interface IButtonAction {
    text: string,
    action: any
}

interface IMediaAddListProps {
    medias: any[],
    onChangeMedias: any,
    onUploadMedias?: () => void,
    oldMedias?: IMedia[],
    //onOldMediaDelete?: (id:number) => Promise<{success:boolean, message: string}>,
    onOldMediaDelete: (id:IMedia['id']) => void
    buttonRemove?: boolean,
    buttonUpload?: boolean

    buttonAvatar?: boolean
    buttonAvatarAction?: (id:IMedia['id'], indx?: number) => void
}

const MediaAddList:FC<IMediaAddListProps> = ({
                                                 medias,
                                                 onChangeMedias,
                                                 onUploadMedias,
                                                 oldMedias= [],
                                                 onOldMediaDelete,
                                                 buttonRemove= true,
                                                 buttonUpload = false,
                                                 buttonAvatar= false,
                                                 buttonAvatarAction,
                                             }) => {
    const onNewDelete = (indx: number) => {
        let tmpData = [...medias];
        tmpData.splice(indx, 1);
        onChangeMedias(tmpData);
    }

    const onNewUpload = () => {
        if(medias.length == 0) {
            showMessage({
                message: capitalizeFirstLetter(translate(Dictionary.media.noNewMediaForUpload)),
                type: "danger"
            });
            return;
        }
        if(onUploadMedias) {
            onUploadMedias();
            return;
        }
    }

    const onOldDelete = (id: IMedia['id']) => {
        if(onOldMediaDelete) {
            onOldMediaDelete(id);
        }
    }

    const onItemPress = () => {

    }

    const onChoose = () => {
        try {
            DocumentPicker.pick({
                type: [DocumentPicker.types.allFiles],
            }).then( (res) => {
                onChangeMedias(medias.concat(res));
            });
        } catch (err) {
            if (DocumentPicker.isCancel(err)) {
            } else {
                throw err;
            }
        }
    }



    return (
        <>
            <View style={{alignContent: "center", alignItems:"center", justifyContent: "center",}}>
                <View>
                    <Text style={globalStyles.bodySectionTitle}>{capitalizeFirstLetter(translate(Dictionary.common.addMedias))}</Text>
                </View>
                <View style={styles.galleryContainer}>
                    <View key={-1} style={{justifyContent: 'center'}}>
                        <TouchableOpacity onPress={onChoose} style={{marginBottom:20}}>
                            <Image style={{ height: 85, width:105, resizeMode:"contain"}} source={addMediaBig} />
                        </TouchableOpacity>
                    </View>
                    {medias && medias.map( (image:any, indx:number) =>
                        <View key={indx} style={{justifyContent: 'center', width:110, height:105}}>
                            <Image style={[styles.image]} source={{uri:image.uri}}/>
                            /*
                            <BorderlessButton style={{paddingLeft:25}} onPress={ () => { onNewDelete(indx)} }>
                                <Text>{capitalizeFirstLetter(translate("common.delete"))}</Text>
                            </BorderlessButton>
                            */
                        </View>
                    )}
                </View>
                {buttonUpload &&
                <View>
                    <Button text={capitalizeFirstLetter(translate(Dictionary.common.upload))} action={onNewUpload}/>
                </View>
                }

            </View>
            <View>
                <View>
                    <Text style={globalStyles.bodySectionTitle}>{capitalizeFirstLetter(translate(Dictionary.profile.medias))}</Text>
                </View>
                <View style={styles.galleryContainer}>
                    {oldMedias && oldMedias.length > 0 && oldMedias.map( (media:IMedia, indx:number) =>
                        <View key={indx} style={{justifyContent: 'center', width:110,  height:100}}>
                            <Image style={[styles.image]} resizeMode={"cover"} source={getMediaPreviewSrc(media)}/>
                            <View style={{width:106,  flexDirection: "row", justifyContent: "space-between"}}>
                                /*
                                {buttonAvatar && buttonAvatarAction &&
                                    <BorderlessButton style={{}} onPress={ () => { buttonAvatarAction(media.id, indx) } }>
                                        <Text>Аватар</Text>
                                    </BorderlessButton>
                                }
                                {buttonRemove &&
                                <BorderlessButton onPress={() => {
                                    onOldDelete(media.id)
                                }}>
                                    <Text>{capitalizeFirstLetter(translate("common.delete"))}</Text>
                                </BorderlessButton>
                                } */
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </>
    );
};

export default MediaAddList;
