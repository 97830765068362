export type ITranslateKey = string;
export type ITranslateString = string;



export const Dictionary: any = {
    common: {
        "yes": "common.yes",
        "no": "common.no",
        "description": "common.description",
        "color": "common.color",
        "back": "common.back",
        "cancel": "common.cancel",
        "continue": "common.continue",
        "hide": "hide",
        "add": "common.add",
        "address": "common.address",
        "contacts": "common.contacts",
        "delete": "common.delete",
        "photo": "common.photo",
        "close": "common.close",
        "success": "success",
        addMedias: "common.addMedias",
        upload: "common.upload"
    },
    errors: {
        "unknownError": "errors.",
        badWords: "errors.badWords",
        "Credential_Mismatch": "errors.Credential_Mismatch",
        "The_old_password_must_be_at_least_8_characters": "errors.The_old_password_must_be_at_least_8_characters",
        "The_email_must_be_a_valid_email_address": "errors.The_email_must_be_a_valid_email_address",
        "The_selected_email_is_invalid": "errors.The_selected_email_is_invalid",
        "The_age_must_be_an_integer": "errors.The_age_must_be_an_integer",
        "The_age_field_is_required": "errors.The_age_field_is_required",
        "The_color_field_is_required": "errors.The_color_field_is_required",
        "The_description_field_is_required": "errors.The_description_field_is_required",
        "The_evt_ctgy_id_field_is_required": "errors.The_evt_ctgy_id_field_is_required",
        "The_address_field_is_required": "errors.The_address_field_is_required",
        "The_contact_details_field_is_required": "errors.The_contact_details_field_is_required",
        "The_selected_evt_ctgy_id_is_invalid":"errors.The_selected_evt_ctgy_id_is_invalid"
    },

    event: {
        event: "event.event",
        events: "event.events",
        noFavs: "event.noFavs",
        feed: "event.feed",
        addSuccess: "event.addSuccess"
    },
    comment: {
        comments: "comment.comments",
        add: "comment.add",
        addSuccess: "comment.addSuccess",
    },
    media: {
        section: "media",
        noNewMediaForUpload: "media.noNewMediaForUpload",
        deleteSuccess: 'media.deleteSuccess'
    },
    pet: {
        addSuccess: "pet.addSuccess",
    },
    post: {
        post: 'post.post',
        posts: 'post.posts',
        addPost: "post.addPost",
        addPostSuccess: "post.addPostSuccess"
    },
    profile: {
        section: 'profile',
        medias: 'profile.medias',
        addMediasSuccess: 'profile.addMediasSuccess',
        updateAvatarSuccess: "profile.updateAvatarSuccess"
    }

}
