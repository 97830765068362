import React, {FC, useEffect, useState} from 'react';
import {Platform, ScrollView, Text, TextInput, View,} from 'react-native';
import styles from './styles';
import DropDownPicker from 'react-native-dropdown-picker';
import {useSelector} from 'react-redux';
import {translate} from "../../../utils/translate";
import {colors} from "../../../constants/Colors";
import ButtonSaveEvent from "../../../components/Project/ButtonSaveEvent/ButtonSaveEvent";
import BackButton from "../../../components/UI/BackButton/BackButton";
//import {launchCamera, launchImageLibrary} from 'react-native-image-picker';
import CheckBox from "@react-native-community/checkbox";
import {showMessage} from "react-native-flash-message";
import {createPet} from "../../../api/pets/createPet/createPet";
import {createEvent} from "../../../api/events/createEvent/createEvent";
import {IMapCenterPoint} from "../../../components/MapComponent/types";
//import MapComponent from "../../../components/MapComponent/MapComponent";
import {SCREEN_WIDTH} from "../../../constants/globalStyles";
import Geocoder from 'react-native-geocoding';
import {geocodeLocation, geocodeReverse, getLocation} from "../../../utils/geo";
import {IEventFormScreen, IEventOnFieldChange} from "../../../components/Project/EventForm/types";
import {
  fieldsSettings,
  FORM_TYPE_ALL,
  FORM_TYPE_EMERGENCY,
  FORM_TYPE_HELP,
  FORM_TYPE_LEISURE,
  FORM_TYPE_PETS,
  FORM_TYPE_PETS_COMMERCIAL,
  FORM_TYPE_SERVICES
} from "../../../components/Project/EventForm/data";
import {getPetTypes} from "../../../api/pets/getPetTypes/getPetTypes";
import {getSubCategories} from "../../../api/categories/getSubCategories/getSubCutegories";
import {addEventMediaMultiply} from "../../../api/events/addEventMediaMultiply/addEventMediaMultiply";
import EventFormEmergency from "./EventFormEmergency/EventFormEmergency";
import MediaAddList from "../../../components/UI/MediaAddList/MediaAddList";
import {fieldsDataDefault, fieldsPetDataDefault, mapCenterDefault, petsListDefault} from "./data";
import {getFormData} from "../../../utils/formData";
import {responseWithBadWords} from "../../../utils/response";
import {Dictionary} from "../../../locales/dictionary";
import {getUserById} from "../../../api/user/getUserById/getUserById";
import {IPet} from "../../../types";

const google_api = "AIzaSyD0P5SiyDBo9Y8BcXIcTm-j6_pO8S7E3-A";
Geocoder.init(google_api, {language:'ru'});

const EventFormScreen: FC<IEventFormScreen> = ({navigation, route}) => {

  const userInfo = useSelector((state: any) => state?.session?.userData);

  const [formState, setFormState] = useState({
    petValue: null,
    isPetList: false,
    isLocationLoading: false,

    formType: FORM_TYPE_EMERGENCY,
    formFields: [],

    openCategory: false,
    categoryValue: 0,
    categories: [],

    openSubCategory: false,
    subCategoryValue: 0,
    subCategories: [],

    fieldsData: {...fieldsDataDefault},
    fieldsPetData: {...fieldsPetDataDefault},
    isEmergency: false,

    medias: [],

    getLocationState: false,
    userMapLocation: {...mapCenterDefault},

  })
  const categoriesData = useSelector((state: any) => state?.global?.categories ?? []);

  const [petValue, setPetValue] = useState(null);
  const [isPetList, setIsPetList] = useState<boolean>(false);
  const [isLocationLoading, setIsLocationLoading] = useState<boolean>(false);

  const [formType, setFormType] = useState<string>(FORM_TYPE_ALL);
  const [formTypes, setFormTypes] = useState<any[]>([]);
  const [formFields, setFormFields] = useState<any>({});

  const [openCategory, setOpenCategory] = useState(false);
  const [categoryValue, setCategoryValue] = useState(0);
  const [categories, setCategories] = useState([]);

  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [subCategoryValue, setSubCategoryValue] = useState(0);
  const [subCategories, setSubCategories] = useState([]);

  const [fieldsData, setFieldsData] = useState({...fieldsDataDefault});
  const [fieldsPetData, setPetFieldsData] = useState({...fieldsPetDataDefault});

  const [medias, setMedias] = useState<any[]>([]);

  const [isEmergency, setIsEmergency] = useState<boolean>(false);
  const [getLocationState, setGetLocationState] = useState<boolean>(false);
  const [userMapLocation, setUserMapLocation] = useState<IMapCenterPoint>({...mapCenterDefault});

  const [openPetTypesList, setOpenPetTypesList] =  useState<boolean>(false);
  //const petsData = useSelector((state: any) => state?.global?.pets ?? []);
  const [petsData, setPetsData] =  useState<IPet[]>([]);

  const [openPetList, setOpenPenPetList] = useState(false);
  const [petList, setPetList] = useState([...petsListDefault]);

  const [petTypesList, setPetTypesList] =  useState<any[]>([]);
  const [petTypesHash, setPetTypesHash] = useState<any>({});
  const [petTypeValue, setPetTypeValue] = useState<number>(0);

  const [petSexValue, setPetSexValue] = useState<number>(-1);
  const [openPetSexList, setOpenPetSexList] =  useState<boolean>(false);
  const [petSexList, setPetSexList] =  useState<any[]>([]);

  const [petSterValue, setPetSterValue] = useState<number>(-1);
  const [openPetSterList, setOpenPetSterList] =  useState<boolean>(false);
  const [petSterList, setPetSterList] =  useState<any[]>([]);

  useEffect(() => {
    let data: any = [];
    //const { formTypeProp = false } = route.params;
    if(formType != FORM_TYPE_EMERGENCY) {
      categoriesData.forEach((item: any) => {
        data.push({
          label: item.name,
          value: item.id,
        });
      });
    }

    setCategories(data);
    return () => {};
  }, [categoriesData, formType]);

  const addEventImages = async (eventId: any) => {
    let imageForm = new FormData();
    let mediaFiles: any[] = [];
    imageForm.append("event_id", eventId);

    medias.map( async (image, index) => {
      imageForm.append(`media_files[${index}]`,{
        name: image.name,
        type: image.type,
        uri: Platform.OS === 'ios' ? image.uri.replace('file://', '') : image.uri,
      });
    })
    const response = await addEventMediaMultiply(imageForm);
    return true;
  }

  const addEvent = async( data:any ) => {
    const formData = getFormData(data);
    const resp = await createEvent(formData);
    return resp;
  }

  const addEventPet = async( petData:any ) => {
    const petFormData = getFormData(petData);
    const resp = await createPet(petFormData);

    if(resp.success) {
      showMessage({
        message: translate("pet.addSuccess"),
        type: "success"
      });
      return resp.data;
    } else {
      showMessage({
        message: [translate("pet.addError"), translate('errors.' + resp.message)].join('. '),
        type: "danger"
      });
      return 0;
    }
  }

  const onSubmit = async () => {
    //props.onHideModal();
    try {
      // 1. Event Data generate
      const eventData = {
        evt_ctgy_id: ( checkFieldVisibility('evt_subctgy_id') ) ? subCategoryValue:categoryValue,
        evt_topic: fieldsData.evt_topic,
        evt_address: [fieldsData['city'], fieldsData['address'] ].join(', '),
        evt_lat: fieldsData.evt_lat,
        evt_long: fieldsData.evt_long,
        evt_images: [],
        description: fieldsData.description,
        evt_priority: (fieldsData.is_emergency === 1) ? 3:1,
        evt_status: null,
        is_emergency: fieldsData.is_emergency,
        price_from: fieldsData.price_from,
        price_to: fieldsData.price_to,
        type: 1
      };

      //2. Add Pet If Need
      if(checkFieldVisibility('pets') && (petValue == 0 || petValue === null)) {
        const petData = {
          ...fieldsPetData,
          address: eventData.evt_address,
          description: fieldsPetData.color,
          sex: petSexValue,
          sterilization: petSterValue,
        }

        const petId = await addEventPet(petData);
        if (petId == 0) {
          return false;
        } else {

          let newPetList = [...petList];
          newPetList.push({
            label: `${petTypesHash[petData.type_id]['type_name']} (${petData.description})`,
            value: petId
          });
          setPetList(newPetList);

          setPetValue(petId);
          setIsPetList(false);
        }
        //@ts-ignore
        eventData.pet_id = petId;
      }
      //TODO:: Add Pet Photo in Event
      //3. Add Pet Photo if need

      //4. Add Event
      const eventResponse = await addEvent(eventData);
      responseWithBadWords({
        resp: eventResponse,
        messageBadWords: Dictionary.errors.badWords,
        messageError: "errors." + eventResponse.message,
        callBackSuccess: () => {
          addEventImages(eventResponse.data).then(() => {
            showMessage({
              message: translate(Dictionary.event.addSuccess),
              type: "success"
            });
            navigation.goBack();
          });
        }
      })

    } catch (error: any) {}
  };

  const onFieldChange = ({form = 'event', field, data}:IEventOnFieldChange) => {
    if(form == 'event') {
      switch (field) {
        case 'is_emergency': {
          setIsEmergency(data);
          if(data) {
            //setFormType(FORM_TYPE_EMERGENCY);
          }
          data = (data == true) ? 1 : 0;
          break;
        }
        case 'city':
        case 'address': {
          let tmpAddressData = {...fieldsData};
          tmpAddressData[field] = data;
          onAddressChange(tmpAddressData.city, tmpAddressData.address).then();
          break;
        }
      }
      let tmpFieldsData = {...fieldsData};
      //@ts-ignore
      tmpFieldsData[field] = data;
      setFieldsData(tmpFieldsData);
    }

    else if(form == 'pet') {
      let tmpFieldsData = {...fieldsPetData};
      //@ts-ignore
      tmpFieldsData[field] = data;
      setPetFieldsData(tmpFieldsData)
    }
  }

  const getLocationCurrent = async () => {
    if(!isLocationLoading) {
      setIsLocationLoading(true);
      getLocation().then((location) => {
        setUserMapLocation({
          latitude: location.latitude,
          longitude: location.longitude,
          latitudeDelta: 0.005,
          longitudeDelta: 0.005 * SCREEN_WIDTH / 200,
        });
        setGetLocationState(true);
        setLocationFieldsData(location).then();
        setIsLocationLoading(false);
      });
    }

  }

  const onLocationChange = (location?: any) => {
    if(location == undefined) {
      getLocationCurrent().then();
    } else {
      if(userMapLocation.latitude == location.latitude && userMapLocation.longitude == location.longitude) {
        //return false;
      } else {
        setUserMapLocation({
          latitude: location.latitude,
          longitude: location.longitude,
          latitudeDelta: 0.005,
          longitudeDelta: 0.005*SCREEN_WIDTH/200,
        });
        setLocationFieldsData(location).then();
        setGetLocationState(true);
      }
    }
  }

  const onAddressChange = async (city: any, address:any) => {
    if(city = fieldsData.city && address == city.address) {
      return false;
    }

    const resp = await geocodeReverse(city + ', ' + address);
    const location = {
      latitude: resp.results[0].geometry.location.lat,
      longitude: resp.results[0].geometry.location.lng,
    }

    if(userMapLocation.latitude = location.latitude && userMapLocation.longitude == location.longitude) {
      return false;
    }

    setLocationFieldsData(location, city, address).then();
    let tmpLocation = {...userMapLocation};
    tmpLocation.latitude = location.latitude;
    tmpLocation.longitude = location.longitude;
    setUserMapLocation(tmpLocation);
    /*
        setUserMapLocation({
              ...userMapLocation,
              latitude: location.latitude,
              longitude: location.longitude,
            }
        );
    */

  }

  const setLocationFieldsData = async (location: any, city?: string, address?:string) => {
    let newFieldsData = {...fieldsData,
      evt_lat: location.latitude,
      evt_long: location.longitude,
      city: '',
      address: '',
    }
    if(city == undefined || address == undefined) {
      const geocodeAddress = await geocodeLocation(location);
      newFieldsData.city = geocodeAddress[0];
      newFieldsData.address = geocodeAddress[1] + ', ' + geocodeAddress[2];
    } else {
      newFieldsData.city = city;
      newFieldsData.address = address;
    }
    setFieldsData(newFieldsData);
  }

  const getPetTypesList = () => {
    getPetTypes().then( resp => {
      let tmpHash:any = {};
      let tmpList:any[] = [];
      resp.map( (petType:any) => {
        tmpHash[petType.id] = petType;
        tmpList.push({
          label: translate(`pet.type_${petType.type_name}`),
          value: petType.id
        });
      });
      setPetTypesHash(tmpHash);
      setPetTypesList(tmpList);
    });
  }

  const getSubCategoriesList = async (id:number) => {
    const resp = await getSubCategories({category_id:id});
    let data: any = [];
    resp.data.forEach((item: any) => {
      data.push({
        label: `${item.name}`,
        value: item.id,
      });
    });
    setSubCategories(data);
  }

  //initial Reaction
  useEffect( () => {
    getUserById({user_id: userInfo.user_id}).then((dataUser) => {
      //setProfileInfo(dataUser);
      setPetsData(dataUser.pets);
      //setEvents(dataUser.events);
    });

    getPetTypesList();

    const { category_id = 1, formTypeProp = false, location = undefined } = route.params;
    if(formTypeProp != false) {
      setFormType(formTypeProp);
    } else {
      setCategoryValue(category_id);
    }

    onLocationChange(location);
    setPetSexList([
      {
        label: translate('pet.sex_m'),
        value: 0
      },
      {
        label: translate('pet.sex_f'),
        value: 1
      }
    ]);
    setPetSterList([
      {
        label: translate('pet.ster_no'),
        value: 0
      },
      {
        label: translate('pet.ster_yes'),
        value: 1
      }
    ]);
  }, []);

  //Reaction on change of category or subCategory
  useEffect( () => {
    const { formTypeProp = false } = route.params;
    if(formTypeProp !== FORM_TYPE_EMERGENCY) {
      switch (categoryValue) {
        case 1: {
          if (subCategoryValue != 9) {
            setFormType(FORM_TYPE_PETS);
          } else {
            setFormType(FORM_TYPE_PETS_COMMERCIAL);
          }

          break;
        }
        case 2: {
          setFormType(FORM_TYPE_HELP);
          break;
        }
        case 3: {
          setFormType(FORM_TYPE_SERVICES);
          break;
        }
        case 5: {
          setFormType(FORM_TYPE_LEISURE);
          break;
        }
        default: {
          setFormType(FORM_TYPE_ALL);
          break;
        }
      }
    }
  },[categoryValue, subCategoryValue]);

  useEffect(() => {
    if(categoryValue && categoryValue>0) {
      getSubCategoriesList(categoryValue).then();
      setSubCategoryValue(0);
    }
  }, [categoryValue])

  useEffect(() => {
    onFieldChange({form:'pet', field:'type_id', data: petTypeValue})
  }, [petTypeValue])

  useEffect(() => {
    if (petsData.length > 0) {
      let data: any = [];
      data.push({
        label: translate('event.petListPlaceholder'),
        value: 0,
      });
      petsData.forEach((item: any) => {
        data.push({
          label: `${item.type} (${item.description})`,
          value: item.id,
        });
      });
      setPetList(data);
    }
    return () => {};
  }, [petsData, isPetList]);

  useEffect(() => {
    onLocationChange(userMapLocation);
    //console.log('getLocationState',getLocationState);
  }, [getLocationState])

  useEffect(() => {

    let data: any = [];
    if(formType == FORM_TYPE_EMERGENCY) {
      onFieldChange({field: 'is_emergency', data: true})
      data.push({
        label: 'Питомцы',
        value: 1,
      });
      data.push({
        label: 'Помощь',
        value: 2,
      });

    } else {
      categoriesData.forEach((item: any) => {
        data.push({
          label: item.name,
          value: item.id,
        });
      });
    }

    setCategories(data);
    // @ts-ignore
    setFormFields(fieldsSettings[formType]);
  }, [formType])

  const checkFieldVisibility = (field:any) => {
    if(formFields && formFields[field] !== undefined && formFields[field]) {
      return true;
    } else {
      return false;
    }
  }

  const onChangeMedias = (data:any[]) => {
    setMedias(data);
  }


  return (
      <View style={styles.containerMain}>
        <BackButton
            text={translate('back')}
            //@ts-ignore
            action={() => navigation.goBack()}
        />
        <ScrollView
            contentContainerStyle={{
              paddingBottom: 20,
            }}
            showsVerticalScrollIndicator={false}>
          <View style={styles.container}>
            <View style={styles.mapSection}>
              /*
              {getLocationState &&

              <MapComponent
                  forceCenter={true}
                  onPressEvent={() => {
                  }}
                  eventMarkers={[]}
                  MapCenterPoint={userMapLocation}
                  onRegionChangeComplete={ (region:any ) => onLocationChange(region)}
                  showCenter={false}
              />


              } */
            </View>

            <View style={styles.formContainer}>
              {formType == FORM_TYPE_EMERGENCY && <EventFormEmergency />}
              <TextInput
                  placeholder={translate('event.topic')}
                  placeholderTextColor={colors.halfCedar}
                  style={[styles.input]}
                  underlineColorAndroid={colors.greenPH}
                  onChangeText={(data) => {
                    onFieldChange({field: 'evt_topic', data: data})
                  }}
              />

              {checkFieldVisibility('evt_ctgy_id') &&
              <DropDownPicker
                  open={openCategory}
                  value={categoryValue}
                  items={categories}
                  setOpen={setOpenCategory}
                  //@ts-ignore: Unreachable code error
                  setValue={setCategoryValue}

                  listMode="SCROLLVIEW"
                  placeholder={translate('category')}
                  placeholderStyle={{
                    color: 'gray',
                    // fontWeight: "bold"
                  }}
                  dropDownDirection="AUTO"
                  style={styles.dropdown}
                  dropDownContainerStyle={styles.dropdownContainer}

              />
              }
              {checkFieldVisibility('evt_subctgy_id') &&
              <DropDownPicker
                  open={openSubCategory}
                  value={subCategoryValue}
                  items={subCategories}
                  setOpen={setOpenSubCategory}
                  //@ts-ignore: Unreachable code error
                  setValue={setSubCategoryValue}

                  listMode="SCROLLVIEW"
                  placeholder={'Подкатегории'}
                  placeholderStyle={{
                    color: 'gray',
                    // fontWeight: "bold"
                  }}
                  dropDownDirection="AUTO"
                  style={styles.dropdown}
                  dropDownContainerStyle={styles.dropdownContainer}

              />
              }
              <View style={styles.textInputsRow}>
                <TextInput
                    value={fieldsData.city}
                    placeholder={translate('city')}
                    placeholderTextColor={colors.halfCedar}
                    style={[styles.input, styles.halfInput]}
                    underlineColorAndroid={colors.greenPH}
                    onChangeText = { ( data ) => { onFieldChange({field: 'city', data: data}) } }
                />
                <TextInput
                    value={fieldsData.address}
                    placeholder={translate('address')}
                    placeholderTextColor={colors.halfCedar}
                    style={[styles.input, styles.halfInput]}
                    underlineColorAndroid={colors.greenPH}
                    onChangeText = { ( data ) => { onFieldChange({field: 'address', data: data})} }
                />
              </View>
              {checkFieldVisibility('is_emergency') &&
              <View style={{flexDirection: 'row', justifyContent: "center", alignItems: 'center'}}>
                <View>
                  {/*@ts-ignore*/}
                  <CheckBox
                      value={isEmergency}
                      onValueChange={(value) => {
                        onFieldChange({field: 'is_emergency', data: value})
                      }}
                  />

                </View>
                <Text>{translate('event.emergency')}</Text>
                <EventFormEmergency showText={false} iconSize={22} />

              </View>
              }

              {checkFieldVisibility('price_from') && checkFieldVisibility('price_to') &&
              <View style={styles.textInputsRow}>
                <TextInput
                    value={fieldsData.price_from}
                    placeholder={translate('event.priceFrom')}
                    placeholderTextColor={colors.halfCedar}
                    style={[styles.input, styles.halfInput]}
                    underlineColorAndroid={colors.greenPH}
                    onChangeText={(data) => {
                      onFieldChange({field: 'price_from', data: data})
                    }}
                />
                <TextInput
                    value={fieldsData.price_to}
                    placeholder={translate('event.priceTo')}
                    placeholderTextColor={colors.halfCedar}
                    style={[styles.input, styles.halfInput]}
                    underlineColorAndroid={colors.greenPH}
                    onChangeText={(data) => {
                      onFieldChange({field: 'price_to', data: data})
                    }}
                />
              </View>
              }

              {/*<EventPetForm />*/}
              {checkFieldVisibility('pets') &&
              <View style={{marginTop: 10}}>
                <DropDownPicker
                    open={openPetList}
                    value={petValue}
                    items={petList}
                    setOpen={setOpenPenPetList}
                    //@ts-ignore: Unreachable code error
                    setValue={setPetValue}
                    listMode="SCROLLVIEW"
                    placeholder={translate('event.petListPlaceholder')}
                    placeholderStyle={{
                      color: 'gray',
                      // fontWeight: "bold"
                    }}
                    dropDownDirection="AUTO"
                    style={styles.dropdown}
                    dropDownContainerStyle={styles.dropdownContainer}
                />
              </View>
              }

              {checkFieldVisibility('pets') && (petValue === null || petValue === 0) &&
              <View style={{marginTop: 10}}>
                <View style={styles.textInputsRow}>
                  <View style={styles.halfInput}>
                    <DropDownPicker
                        open={openPetTypesList}
                        setOpen={setOpenPetTypesList}
                        value={petTypeValue}
                        items={petTypesList}

                        //@ts-ignore: Unreachable code error
                        setValue={setPetTypeValue}
                        listMode="SCROLLVIEW"
                        placeholder={translate('pet.type_id')}
                        placeholderStyle={{
                          color: 'gray',
                          // fontWeight: "bold"
                        }}
                        dropDownDirection="AUTO"
                        style={[styles.dropdown]}
                        dropDownContainerStyle={styles.dropdownContainer}
                    />
                  </View>
                  <TextInput
                      placeholder={translate('age')}
                      placeholderTextColor={colors.halfCedar}
                      style={[styles.input, styles.halfInput]}
                      underlineColorAndroid={colors.greenPH}
                      onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'age', data: data})
                      }}
                  />
                </View>
                <View style={styles.textInputsRow}>
                  <View style={styles.halfInput}>
                    <DropDownPicker
                        open={openPetSexList}
                        setOpen={setOpenPetSexList}
                        value={petSexValue}
                        items={petSexList}

                        //@ts-ignore: Unreachable code error
                        setValue={setPetSexValue}
                        listMode="SCROLLVIEW"
                        placeholder={translate('pet.sex')}
                        placeholderStyle={{
                          color: 'gray',
                          // fontWeight: "bold"
                        }}
                        dropDownDirection="AUTO"
                        style={[styles.dropdown]}
                        dropDownContainerStyle={styles.dropdownContainer}
                    />
                  </View>
                  {checkFieldVisibility('pet_color') &&
                  <TextInput
                      placeholder={translate('color')}
                      placeholderTextColor={colors.halfCedar}
                      style={[styles.input, styles.halfInput]}
                      underlineColorAndroid={colors.greenPH}
                      onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'color', data: data})
                      }}
                  />
                  }
                </View>

                <View style={styles.textInputsRow}>
                  {checkFieldVisibility('pet_species') &&
                  <TextInput
                      placeholder={translate('pet.species')}
                      placeholderTextColor={colors.halfCedar}
                      style={[styles.input, styles.halfInput]}
                      underlineColorAndroid={colors.greenPH}
                      onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'species', data: data})
                      }}
                  />
                  }
                  {checkFieldVisibility('pet_name') &&
                  <TextInput
                      placeholder={translate('pet.name')}
                      placeholderTextColor={colors.halfCedar}
                      style={[styles.input, styles.halfInput]}
                      underlineColorAndroid={colors.greenPH}
                      onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'name', data: data})
                      }}
                  />
                  }
                </View>

                <View style={styles.textInputsRow}>

                  {checkFieldVisibility('pet_chip') &&
                  <TextInput
                      placeholder={translate('chip')}
                      placeholderTextColor={colors.halfCedar}
                      style={[styles.input, styles.halfInput]}
                      underlineColorAndroid={colors.greenPH}
                      onChangeText={(data) => {
                        onFieldChange({form: 'pet', field: 'chip', data: data})
                      }}
                  />
                  }
                  {checkFieldVisibility('pet_sterilization') &&
                  <View style={styles.halfInput}>
                    <DropDownPicker
                        open={openPetSterList}
                        setOpen={setOpenPetSterList}
                        value={petSterValue}
                        items={petSterList}

                        //@ts-ignore: Unreachable code error
                        setValue={setPetSterValue}
                        listMode="SCROLLVIEW"
                        placeholder={translate('pet.ster')}
                        placeholderStyle={{
                          color: 'gray',
                          // fontWeight: "bold"
                        }}
                        dropDownDirection="AUTO"
                        style={[styles.dropdown]}
                        dropDownContainerStyle={styles.dropdownContainer}
                    />
                  </View>
                  }
                </View>

              </View>
              }



              {checkFieldVisibility('description') &&
              <TextInput
                  placeholder={translate('description')}
                  placeholderTextColor={colors.halfCedar}
                  style={styles.bigInput}
                  onChangeText={(data) => {
                    onFieldChange({field: 'description', data: data})
                  }}
              />
              }

              <MediaAddList medias={medias} onChangeMedias={onChangeMedias} />

              <View style={styles.submitButton}>
                <ButtonSaveEvent
                    onPress={onSubmit}
                    buttonText={translate('event.addEvent')}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
  );
};

export default EventFormScreen;
