import React, {FC, useEffect, useState} from 'react';
import {FlatList, Image, TouchableOpacity, View} from 'react-native';
import styles from './styles';
// @ts-ignore
import ImageView from 'react-native-image-viewing';
import {getMediaFullSrc, getMediaPreviewSrc} from "../../../utils/common";
import {IMedia} from "../../../types";
import ModalSimple from "../../UI/ModalSimple/ModalSimple";

// @ts-ignore
//import Video from 'react-native-video-controls';
import VideoPlayer from "../../UI/VideoPlayer/VideoPlayer";


interface IFlatListMediaProps {
    onItemPress: (item: any) => void;
    items: IMedia[];
    activeItem?: number;
};

interface IFlatListMediaState {
    isImagesViewVisible: boolean,
    isVideoVisible: boolean,
    medias: IMedia[],
    images: any[],
    currentImage: number,
    currentVideo: number,
    isVideoPaused: boolean
}


const FlatListMedia: FC<IFlatListMediaProps> = ({onItemPress,items,activeItem = 0 }) => {
    console.log('TEST');
    const [compState, setCompState] = useState<IFlatListMediaState>({
        isImagesViewVisible: false,
        medias: items,
        images: [],
        currentImage: 0,
        currentVideo: 0,
        isVideoVisible: false,
        isVideoPaused: true
    });

    useEffect( () => {
        console.log('MEDIAS FOR VIEW', compState);
        //TODO:: реализовать связку медиа индексов с индексами картинок
        let images: typeof compState.images = [];
        compState.medias.map( item => {
            if(item.media_type == "image") {
                images.push(getMediaFullSrc(item));
            }
        })
        console.log('IMAGES FOR VIEW', images);
        setCompState({...compState, images: images});
    }, [])

    const renderItem = ({item, index}: { item:IMedia, index:number }) => {

        return (
            <TouchableOpacity
                key={index}
                onPress={() => {

                    if(item.media_type == "video") {
                        setCompState({...compState, isVideoVisible: true, currentVideo: index, isVideoPaused: false});
                    } else {
                        setCompState({...compState, isImagesViewVisible: true, currentImage: index});
                    }

                }}
                style={{width: 200,height: 200}}
            >
                <Image resizeMode="contain" source={getMediaPreviewSrc(item)} style={{
                    width: 200,
                    height: 200
                }}/>
            </TouchableOpacity>

        );
    };
    return (
        <View style={styles.container}>

            <ModalSimple compState = {compState} isVisible={compState.isVideoVisible} toggleModal={() => {} } styleModalView={{borderWidth:1, width:"100%", height: "100%"}}>
                /* <VideoPlayer media={compState.medias[compState.currentVideo]}
                             onClose={ () => { setCompState({...compState, isVideoVisible: false, isVideoPaused: true}) } }
                             isVideoPaused={ compState.isVideoPaused} /> */
            </ModalSimple>

            <FlatList
                showsHorizontalScrollIndicator={false}
                horizontal
                data={compState.medias}
                contentContainerStyle={styles.listContainer}
                renderItem={renderItem}
            />

            <ImageView
                swipeToCloseEnabled
                animationType="fade"
                images={compState.images}
                imageIndex={compState.currentImage}
                visible={compState.isImagesViewVisible}
                onRequestClose={() => setCompState({...compState, isImagesViewVisible: false})}
            />

            {/*
            <ImageView
                controls={{next:true,prev:true,close:true}}
                swipeToCloseEnabled
                images={compState.images}
                imageIndex={compState.currentImage}
                animationType="fade"
                isVisible={compState.isImagesViewVisible}
                onClose={() => setCompState({...compState, isImagesViewVisible: false}) }
            />
            */}


        </View>
    );
};

export default FlatListMedia;
