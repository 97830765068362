import {API_USERS_MODEL} from "../const";
import {API_BASE_URL, apiCall} from "../../api";

export const addUserMediaMultiply = async (payload:any) => {
    const methodUrl = [API_USERS_MODEL.url,API_USERS_MODEL.methods.addMediaMultiply.url].join('/');
    const resp = await apiCall(
        'post',
        API_BASE_URL + methodUrl,
        payload
    );

    if(resp && resp.data.status && resp.data.status == "success") {
        return {"success": true, data: resp.data, message: resp.data.message};
    } else {
        return {"success": false, data: resp.data, message: resp.data.message};
    }
};
